export const msalBaseConfig = {
  redirectUri: process.env.REACT_APP_REDIRECT_URI as string,
  portalApplicationId: process.env.REACT_APP_PORTAL_APP_ID as string,
};
export const directorApiConfig = {
  applicationId: process.env.REACT_APP_WEBSEC_APP_ID,
  apiVersion: process.env.REACT_APP_API_VERSION,
  baseUrl: process.env.REACT_APP_DIRECTOR_BASE_URI,
  swaggerBaseUrl: process.env.REACT_APP_DIRECTOR_SWAGGER_BASE_URI,
};
export const reporterApiConfig = {
  applicationId: process.env.REACT_APP_WEBSEC_APP_ID,
  apiVersion: process.env.REACT_APP_API_VERSION,
  baseUrl: process.env.REACT_APP_REPORTER_BASE_URI,
  swaggerBaseUrl: process.env.REACT_APP_REPORTER_SWAGGER_BASE_URI,
  sarifLogDownloadLink: process.env.REACT_APP_SCAN_RESULT_DOWNLOAD_LINK,
};
export const apiBaseConfig = {
  subscriptionKey: process.env.REACT_APP_API_SUBSCRIPTION_KEY,
};

export const baseConfig = {
  defaultServiceTreeId: process.env.REACT_APP_DEFAULT_SERVICE_TREE_ID,
};
