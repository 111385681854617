import { useTitle } from "../../HeaderUpdator";
import { useGetResultNotificationsQuery } from "../../store/directorApi.generated";
import { Entity } from "../layout/Entity";
import "../layout/Entity.scss";
import "./ResultNotificationList.scss";

export const ResultNotificationList = () => {
  useTitle("Supported Result Notifications");
  const { data } = useGetResultNotificationsQuery();
  if (!data || !data.values) {
    return <h2>Loading result notifications...</h2>;
  }

  if (data.values) {
    return (
      <>
        <h2>Supported Result Notifications</h2>
        <div className="resultNotificationContainer">
          <ul id="supportedResultNotifications">
            {data.values.map((result) => (
              <li aria-labelledby="supportedResultNotifications">
                <Entity
                  children={[
                    { left: "Name:", right: result.name },
                    { left: "Id:", right: result.id },
                    {
                      left: "Description:",
                      right: result.description ?? "Not Configured",
                    },
                  ]}
                  labeledBy="resultNotifications"
                />
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  } else {
    return <h2>Failed to parse result notifications.</h2>;
  }
};
