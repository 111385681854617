import { directorBaseApi as api } from "./directorApiBase";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDetections: build.query<GetDetectionsApiResponse, GetDetectionsApiArg>({
      query: () => ({ url: `/Detections` }),
    }),
    getRegions: build.query<GetRegionsApiResponse, GetRegionsApiArg>({
      query: () => ({ url: `/Regions` }),
    }),
    getResultNotifications: build.query<
      GetResultNotificationsApiResponse,
      GetResultNotificationsApiArg
    >({
      query: () => ({ url: `/ResultNotifications` }),
    }),
    getScanDefinition: build.query<
      GetScanDefinitionApiResponse,
      GetScanDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/ScanDefinitions/${queryArg.scanDefinitionId}`,
      }),
    }),
    patchScanDefinition: build.mutation<
      PatchScanDefinitionApiResponse,
      PatchScanDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/ScanDefinitions/${queryArg.scanDefinitionId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putScanDefinition: build.mutation<
      PutScanDefinitionApiResponse,
      PutScanDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/ScanDefinitions/${queryArg.scanDefinitionId}`,
        method: "PUT",
        body: queryArg.scanDefinitionPutModel,
      }),
    }),
    getScanDefinitions: build.query<
      GetScanDefinitionsApiResponse,
      GetScanDefinitionsApiArg
    >({
      query: (queryArg) => ({
        url: `/ScanDefinitions`,
        params: { limit: queryArg.limit },
      }),
    }),
    postScanDefinition: build.mutation<
      PostScanDefinitionApiResponse,
      PostScanDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/ScanDefinitions`,
        method: "POST",
        body: queryArg.scanDefinitionPostModel,
      }),
    }),
    postScanInstance: build.mutation<
      PostScanInstanceApiResponse,
      PostScanInstanceApiArg
    >({
      query: (queryArg) => ({
        url: `/ScanInstances`,
        method: "POST",
        body: queryArg.scanInstancePostModel,
      }),
    }),
    putScanInstance: build.mutation<
      PutScanInstanceApiResponse,
      PutScanInstanceApiArg
    >({
      query: (queryArg) => ({
        url: `/ScanInstances/${queryArg.scanInstanceId}`,
        method: "PUT",
        body: queryArg.scanInstancePutModel,
      }),
    }),
    getScanRequest: build.query<
      GetScanRequestApiResponse,
      GetScanRequestApiArg
    >({
      query: (queryArg) => ({ url: `/ScanRequests/${queryArg.scanRequestId}` }),
    }),
    postScanRequest: build.mutation<
      PostScanRequestApiResponse,
      PostScanRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/ScanRequests`,
        method: "POST",
        body: queryArg.scanRequestPostModel,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as directorApi };
export type GetDetectionsApiResponse =
  /** status 200 WebSec Detection details found. */ DetectionModelCollectionResponseModelRead;
export type GetDetectionsApiArg = void;
export type GetRegionsApiResponse =
  /** status 200 WebSec Scanning Region details found. */ RegionModelCollectionResponseModelRead;
export type GetRegionsApiArg = void;
export type GetResultNotificationsApiResponse =
  /** status 200 WebSec Scan ResultNotifications found. */ ResultNotificationModelCollectionResponseModelRead;
export type GetResultNotificationsApiArg = void;
export type GetScanDefinitionApiResponse =
  /** status 200 Matching Scan Definition found. */ ScanDefinitionModelRead;
export type GetScanDefinitionApiArg = {
  /** Unique identifier of the Scan Definition to retrieve. */
  scanDefinitionId: string;
};
export type PatchScanDefinitionApiResponse =
  /** status 200 Scan Definition modified successfully. */ ScanDefinitionModelRead;
export type PatchScanDefinitionApiArg = {
  /** Unique identifier of the Scan Definition to modify. */
  scanDefinitionId: string;
  /** Scan Definition change collection (via JSON PATCH). */
  body: Operation[];
};
export type PutScanDefinitionApiResponse =
  /** status 200 Scan Definition successfully modified. */ ScanDefinitionModelRead;
export type PutScanDefinitionApiArg = {
  /** Unique identifier of the Scan Definition to modify. */
  scanDefinitionId: string;
  /** Scan Definition which will replace the existing entity. */
  scanDefinitionPutModel: ScanDefinitionPutModel;
};
export type GetScanDefinitionsApiResponse =
  /** status 200 Collection of matching Scan Definition entities. */ ScanDefinitionModelCollectionResponseModelRead;
export type GetScanDefinitionsApiArg = {
  /** Max number of entities to retrieve. */
  limit?: number;
};
export type PostScanDefinitionApiResponse =
  /** status 201 Scan Definition created successfully. */ ScanDefinitionModelRead;
export type PostScanDefinitionApiArg = {
  /** New Scan Definition entity. */
  scanDefinitionPostModel: ScanDefinitionPostModel;
};
export type PostScanInstanceApiResponse =
  /** status 200 Scan Instance issued. */ ScanInstanceModel;
export type PostScanInstanceApiArg = {
  /** Scan Instance to create. */
  scanInstancePostModel: ScanInstancePostModel;
};
export type PutScanInstanceApiResponse =
  /** status 200 Status update accepted. */ ScanInstanceModel;
export type PutScanInstanceApiArg = {
  /** Unique identifier of the scan instance. */
  scanInstanceId: string;
  /** Status update information. */
  scanInstancePutModel: ScanInstancePutModel;
};
export type GetScanRequestApiResponse =
  /** status 200 Matching Scan Request found. */ ScanRequestModelRead;
export type GetScanRequestApiArg = {
  /** Unique identifier of the Scan Request. */
  scanRequestId: string;
};
export type PostScanRequestApiResponse =
  /** status 202 Scan Request accepted. */ ScanRequestModelRead;
export type PostScanRequestApiArg = {
  /** Scan Request submission data. */
  scanRequestPostModel: ScanRequestPostModel;
};
export type ConfigurationModel = {
  /** URL to the JSON schema documenting configuration properties. */
  configurationSchemaUrl?: string;
  /** Configuration schema version represented. */
  configurationSchemaVersion?: string;
  /** Timestamp of when the schema version was released. */
  whenCreated?: string;
};
export type DetectionModel = {
  /** Detection configuration schema documentation, when applicable. */
  configurationSchemas?: ConfigurationModel[];
  /** Summary of the Detection behavior. */
  description?: string;
  /** Unique identifier of the Detection. */
  id: string;
  /** Flag which determines whether the Detection is currently enabled. */
  isActive?: boolean;
  /** Flag which determines whether the Detection is included by default when none
    are specified on Scan Definition creation. */
  isDefault?: boolean;
  /** Friendly name of the Detection. */
  name: string;
};
export type DetectionModelCollectionResponseModel = {
  /** Objects returned from API query. */
  values?: DetectionModel[];
};
export type DetectionModelCollectionResponseModelRead = {
  /** Number of objects returned in Microsoft.WebSec.Director.Api.V2.Models.CollectionResponseModel`1.Values. */
  count?: number;
  /** Objects returned from API query. */
  values?: DetectionModel[];
};
export type RegionModel = {
  /** Unique identifier of the Scan Region. */
  id: string;
  /** Flag which determines whether the Scan Region is a default when
    none is specified in the Scan Definition. */
  isDefault?: boolean;
  /** Name of the Scan Region. */
  name: string;
};
export type RegionModelCollectionResponseModel = {
  /** Objects returned from API query. */
  values?: RegionModel[];
};
export type RegionModelCollectionResponseModelRead = {
  /** Number of objects returned in Microsoft.WebSec.Director.Api.V2.Models.CollectionResponseModel`1.Values. */
  count?: number;
  /** Objects returned from API query. */
  values?: RegionModel[];
};
export type ResultNotificationModel = {
  /** ResultNotification configuration schema documentation, when applicable. */
  configurationSchemas?: ConfigurationModel[];
  /** Summary of the ResultNotification. */
  description?: string;
  /** Unique identifier of the ResultNotification. */
  id: string;
  /** Friendly name of the ResultNotification. */
  name: string;
};
export type ResultNotificationModelCollectionResponseModel = {
  /** Objects returned from API query. */
  values?: ResultNotificationModel[];
};
export type ResultNotificationModelCollectionResponseModelRead = {
  /** Number of objects returned in Microsoft.WebSec.Director.Api.V2.Models.CollectionResponseModel`1.Values. */
  count?: number;
  /** Objects returned from API query. */
  values?: ResultNotificationModel[];
};
export type ScanDefinitionDetectionModel = {
  /** Additional configuration to be used by the Detection. */
  configuration?: any;
  /** Version of the configuration schema used by Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionDetectionModel.Configuration for Detection. */
  configurationSchemaVersion?: string;
  /** Unique identifier of the Detection. */
  id?: string;
};
export type ScanDefinitionOwnerModel = {
  /** Azure Active Directory Object ID of the identity. */
  aadObjectId?: string;
  /** Alias of the identity. */
  mailNickname: string;
  /** Type of identity. */
  objectType: string;
};
export type ScanDefinitionCredentialModel = {
  /** Gets or sets credential configuration properties. */
  configuration?: any;
  /** Gets or sets the configuration schema version used by Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionCredentialModel.Configuration. */
  configurationSchemaVersion?: string;
  /** Gets or sets the type of authentication mechanism represented by the credential. */
  kind?: string;
};
export type RequestHeaderModel = {
  /** Name of the header. */
  name?: string;
  /** Name of the Key Vault secret containing the value of the header. */
  secretName?: string;
  /** Value of the header. */
  value?: string;
};
export type ScanDefinitionRequestOptionsModel = {
  authentication?: ScanDefinitionCredentialModel;
  /** Type of device to emulate. */
  deviceKind?: string;
  /** Collection of headers to be appended to each web request. */
  headers?: RequestHeaderModel[];
  /** Custom `User-Agent` string to use for each web request. */
  userAgent?: string;
};
export type ScanDefinitionResultNotificationModel = {
  /** Collection of Scan Result notification recipients. */
  audiences?: string[];
  /** Property bag to store additional notification configuration values. */
  configuration?: any;
  /** Version of the configuration schema used by the specified notification service. */
  configurationSchemaVersion?: string;
  /** Minimum Finding severity required for a notification to be triggered. */
  minSeverity?: number;
  /** Value indicating whether a notification should only be triggered if a Scan Result contains a Finding. */
  notifyOnFindingsOnly?: boolean;
  /** Type of notification to be triggered. */
  type?: string;
};
export type ScanRequestScanInstanceModel = {
  /** Unique identifier of the Scan Instance. */
  id?: string;
  /** Unique identifier of the Region represented by the Scan Instance. */
  regionId?: string;
  /** Describes the current Scan Instance status. */
  state: string;
  /** Timestamp, in UTC, when the Scan Instance finished execution. */
  whenCompleted?: string;
  /** Timestamp, in UTC, when the Scan Instance was created. */
  whenCreated?: string;
  /** Timestamp, in UTC, when the Scan Instance record was last updated. */
  whenUpdated?: string;
};
export type ScanRequestModel = {
  /** Unique identifier for the specific Microsoft.WebSec.Director.Api.V2.Models.ScanRequestModel. */
  id?: string;
  /** Collection of Microsoft.WebSec.Director.Api.V2.Models.DetectionModel identifiers which were inactive at time of
    Scan Request creation. */
  inactiveDetections?: string[];
  /** A value indicating whether the Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionResultNotificationModel provided in the request
    should overwrite those configured in the Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionModel. */
  overrideResultNotifications?: boolean;
  /** Collection of Regions where the Scan Request was scheduled to run. */
  regions?: string[];
  /** Collection of Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionResultNotificationModel for the Microsoft.WebSec.Director.Api.V2.Models.ScanRequestModel. */
  resultNotifications?: ScanDefinitionResultNotificationModel[];
  scanDefinition?: ScanDefinitionModel;
  /** Unique identifier of the Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionModel represented in the instance. */
  scanDefinitionId: string;
  /** Collection of individual Microsoft.WebSec.Director.Api.V2.Models.ScanInstanceModel in each Region based on the
    Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionModel configuration. */
  scanInstances?: ScanRequestScanInstanceModel[];
  /** Indicates whether the Callback URL process should be skipped once
    Scan results are ready. */
  skipCallback?: boolean;
  /** Timestamp of when the scan request was queued. */
  whenCreated?: string;
  /** Timestamp of when the scan instance was last updated. */
  whenUpdated?: string;
};
export type ScanRequestModelRead = {
  /** Unique identifier for the specific Microsoft.WebSec.Director.Api.V2.Models.ScanRequestModel. */
  id?: string;
  /** Collection of Microsoft.WebSec.Director.Api.V2.Models.DetectionModel identifiers which were inactive at time of
    Scan Request creation. */
  inactiveDetections?: string[];
  /** Flag which determines whether the Scan Request has been cancelled. */
  isCancelled?: boolean;
  /** Flag which determines whether a fault was encountered attempting to queue the Scan. */
  isFaulted?: boolean;
  /** Flag which indicates whether the Scan Request is in a finished state. */
  isFinished?: boolean;
  /** A value indicating whether the Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionResultNotificationModel provided in the request
    should overwrite those configured in the Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionModel. */
  overrideResultNotifications?: boolean;
  /** Collection of Regions where the Scan Request was scheduled to run. */
  regions?: string[];
  /** Collection of Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionResultNotificationModel for the Microsoft.WebSec.Director.Api.V2.Models.ScanRequestModel. */
  resultNotifications?: ScanDefinitionResultNotificationModel[];
  scanDefinition?: ScanDefinitionModel;
  /** Unique identifier of the Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionModel represented in the instance. */
  scanDefinitionId: string;
  /** Collection of individual Microsoft.WebSec.Director.Api.V2.Models.ScanInstanceModel in each Region based on the
    Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionModel configuration. */
  scanInstances?: ScanRequestScanInstanceModel[];
  /** Indicates whether the Callback URL process should be skipped once
    Scan results are ready. */
  skipCallback?: boolean;
  /** Timestamp of when the scan request was queued. */
  whenCreated?: string;
  /** Timestamp of when the scan instance was last updated. */
  whenUpdated?: string;
};
export type ScanDefinitionSecretVaultModel = {
  /** System.Uri to the Secret Vault to be used by the scanner. */
  vaultUri: string;
};
export type ScanDefinitionModel = {
  /** Azure Active Directory application identifier which is allowed to manage the Scan Definition. */
  applicationId?: string;
  /** Collection of WebSec tests and their configurations which should be run on Scan invocation. */
  detections?: ScanDefinitionDetectionModel[];
  /** Unique identifier of the Scan Definition. */
  id: string;
  /** Indicates whether the Scan is currently allowed to be executed in a Scan Request, either 'As Scheduled' or
    'On Demand'. */
  isActive?: boolean;
  /** Maximum amount of time a Scan is allowed to run before timeout, in minutes. */
  maxScanRuntimeInMinutes?: number;
  /** Timestamp, in UTC, of the next scan invocation. */
  nextScheduledRun?: string;
  /** An Azure Active Directory identity which can manage the Scan Definition. */
  owners: ScanDefinitionOwnerModel[];
  /** Number of hours to wait between automatic scan requests, if the definition should run on a schedule. */
  recurrenceScheduleInHours?: number;
  /** Collection of Scan Region names where tests will be executed. */
  regions?: string[];
  requestOptions?: ScanDefinitionRequestOptionsModel;
  /** Collection of result notification settings for the Scan Definition. */
  resultNotifications?: ScanDefinitionResultNotificationModel[];
  /** Collection of Scan Requests made using the Scan Definition. */
  scanRequests?: ScanRequestModel[];
  secretsLocation?: ScanDefinitionSecretVaultModel;
  /** Service Tree identifier associated with the targeted service. */
  serviceTreeId: string;
  /** Friendly name of the Scan Definition. */
  title: string;
  /** Collection of URLs which should be scanned as part of the Scan Definition. */
  urls: string[];
  /** Timestamp, in UTC, of entity creation. */
  whenCreated?: string;
  /** Timestamp, in UTC, indicating when the Scan Definition should be marked inactive. */
  whenExpires?: string;
  /** Timestamp, in UTC, of the last entity update. */
  whenUpdated?: string;
};
export type ScanDefinitionModelRead = {
  /** Azure Active Directory application identifier which is allowed to manage the Scan Definition. */
  applicationId?: string;
  /** Collection of WebSec tests and their configurations which should be run on Scan invocation. */
  detections?: ScanDefinitionDetectionModel[];
  /** Unique identifier of the Scan Definition. */
  id: string;
  /** Indicates whether the Scan is currently allowed to be executed in a Scan Request, either 'As Scheduled' or
    'On Demand'. */
  isActive?: boolean;
  /** Maximum amount of time a Scan is allowed to run before timeout, in minutes. */
  maxScanRuntimeInMinutes?: number;
  /** Timestamp, in UTC, of the next scan invocation. */
  nextScheduledRun?: string;
  /** An Azure Active Directory identity which can manage the Scan Definition. */
  owners: ScanDefinitionOwnerModel[];
  /** Number of hours to wait between automatic scan requests, if the definition should run on a schedule. */
  recurrenceScheduleInHours?: number;
  /** Collection of Scan Region names where tests will be executed. */
  regions?: string[];
  requestOptions?: ScanDefinitionRequestOptionsModel;
  /** Collection of result notification settings for the Scan Definition. */
  resultNotifications?: ScanDefinitionResultNotificationModel[];
  /** Collection of Scan Requests made using the Scan Definition. */
  scanRequests?: ScanRequestModelRead[];
  secretsLocation?: ScanDefinitionSecretVaultModel;
  /** Service Tree identifier associated with the targeted service. */
  serviceTreeId: string;
  /** Friendly name of the Scan Definition. */
  title: string;
  /** Collection of URLs which should be scanned as part of the Scan Definition. */
  urls: string[];
  /** Timestamp, in UTC, of entity creation. */
  whenCreated?: string;
  /** Timestamp, in UTC, indicating when the Scan Definition should be marked inactive. */
  whenExpires?: string;
  /** Timestamp, in UTC, of the last entity update. */
  whenUpdated?: string;
};
export type ProblemDetails = {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  [key: string]: any;
};
export type OperationType = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type Operation = {
  operationType?: OperationType;
  path?: string;
  op?: string;
  from?: string;
  value?: any;
};
export type ScanDefinitionPutModel = {
  /** Azure Active Directory application identifier which is allowed to manage the Scan Definition. */
  applicationId?: string;
  /** Collection of WebSec tests and their configurations which should be run on Scan invocation. */
  detections?: ScanDefinitionDetectionModel[];
  /** Unique identifier of the Scan Definition. */
  id?: string;
  /** Indicates whether the Scan is currently allowed to be executed in a Scan Request, either 'As Scheduled' or
    'On Demand'. */
  isActive?: boolean;
  /** Maximum amount of time a Scan is allowed to run before timeout, in minutes. */
  maxScanRuntimeInMinutes?: number;
  /** Timestamp, in UTC, of the next scan invocation. */
  nextScheduledRun?: string;
  /** An Azure Active Directory identity which can manage the Scan Definition. */
  owners: ScanDefinitionOwnerModel[];
  /** Number of hours to wait between automatic Scan Requests, if the definition should run on a schedule. */
  recurrenceScheduleInHours?: number;
  /** Collection of Scan Region names where tests will be executed. */
  regions?: string[];
  requestOptions?: ScanDefinitionRequestOptionsModel;
  /** Collection of result notification settings for the Scan Definition. */
  resultNotifications?: ScanDefinitionResultNotificationModel[];
  secretsLocation?: ScanDefinitionSecretVaultModel;
  /** Service Tree identifier associated with the targeted service. */
  serviceTreeId: string;
  /** Friendly name of the Scan Definition. */
  title: string;
  /** Collection of URLs which should be scanned as part of the Scan Definition. */
  urls: string[];
  /** Timestamp, in UTC, indicating when the Scan Definition should be marked inactive. */
  whenExpires?: string;
};
export type ScanDefinitionModelCollectionResponseModel = {
  /** Objects returned from API query. */
  values?: ScanDefinitionModel[];
};
export type ScanDefinitionModelCollectionResponseModelRead = {
  /** Number of objects returned in Microsoft.WebSec.Director.Api.V2.Models.CollectionResponseModel`1.Values. */
  count?: number;
  /** Objects returned from API query. */
  values?: ScanDefinitionModelRead[];
};
export type ScanDefinitionPostModel = {
  /** Azure Active Directory application identifier which is allowed to manage the Scan Definition. */
  applicationId?: string;
  /** Collection of WebSec tests and their configurations which should be run on Scan invocation. */
  detections?: ScanDefinitionDetectionModel[];
  /** Indicates whether the Scan is currently allowed to be executed in a Scan Request, either 'As Scheduled' or
    'On Demand'. */
  isActive?: boolean;
  /** Maximum amount of time a Scan is allowed to run before timeout, in minutes. */
  maxScanRuntimeInMinutes?: number;
  /** Timestamp, in UTC, of the next scan invocation. */
  nextScheduledRun?: string;
  /** An Azure Active Directory identity which can manage the Scan Definition. */
  owners: ScanDefinitionOwnerModel[];
  /** Number of hours to wait between automatic Scan Requests, if the definition should run on a schedule. */
  recurrenceScheduleInHours?: number;
  /** Collection of Scan Region names where tests will be executed. */
  regions?: string[];
  requestOptions?: ScanDefinitionRequestOptionsModel;
  /** Collection of result notification settings for the Scan Definition. */
  resultNotifications?: ScanDefinitionResultNotificationModel[];
  secretsLocation?: ScanDefinitionSecretVaultModel;
  /** Service Tree identifier associated with the targeted service. */
  serviceTreeId: string;
  /** Friendly name of the Scan Definition. */
  title: string;
  /** Collection of URLs which should be scanned as part of the Scan Definition. */
  urls: string[];
  /** Timestamp, in UTC, indicating when the Scan Definition should be marked inactive. */
  whenExpires?: string;
};
export type ScanInstanceModel = {
  /** Unique identifier of the Scan Instance. */
  id?: string;
  /** Unique identifier of the queue message created when scheduling the Scan Instance. */
  queueMessageId?: string;
  /** Receipt identifier from the latest Queue Message operation. */
  queuePutReceipt?: string;
  /** Complete URL to the queue where the Scan Instance message was enqueued. */
  queueUri?: string;
  /** Unique identifier of the Region where the Scanner is located. */
  regionId?: string;
  /** Name of the Scanner executing the scan. */
  scannerName: string;
  /** Unique identifier of the Scan Request being executed. */
  scanRequestId?: string;
  /** Simple value describing the current scan instance state. */
  state: string;
  /** Timestamp of when the scan finished executing. */
  whenCompleted?: string;
  /** Timestamp of when the scan instance was created. */
  whenCreated?: string;
  /** Timestamp of when the instance record was last updated. */
  whenUpdated?: string;
};
export type ScanInstancePostModel = {
  /** Unique identifier of the queue message created when scheduling the Scan Instance. */
  queueMessageId?: string;
  /** Receipt identifier from the latest Queue Message operation. */
  queuePutReceipt?: string;
  /** Complete URL to the queue where the Scan Instance message was enqueued. */
  queueUri?: string;
  /** Unique identifier of the Region where the Scanner is located. */
  regionId?: string;
  /** Name of the Scanner executing the scan. */
  scannerName: string;
  /** Unique identifier of the Scan Request being executed. */
  scanRequestId?: string;
  /** Scan Instance state description. */
  state: string;
  /** Timestamp, in UTC, of when the scan finished execution. */
  whenCompleted?: string;
};
export type ScanInstancePutModel = {
  /** Unique identifier of the Scan Instance. */
  id?: string;
  /** Unique identifier of the queue message created when scheduling the Scan Instance. */
  queueMessageId?: string;
  /** Receipt identifier from the latest Queue Message operation. */
  queuePutReceipt?: string;
  /** Complete URL to the queue where the Scan Instance message was enqueued. */
  queueUri?: string;
  /** Unique identifier of the Region where the Scanner is located. */
  regionId?: string;
  /** Name of the Scanner executing the scan. */
  scannerName: string;
  /** Unique identifier of the Scan Request being executed. */
  scanRequestId?: string;
  /** Scan Instance state description. */
  state: string;
  /** Timestamp, in UTC, of when the scan finished execution. */
  whenCompleted?: string;
};
export type ScanRequestPostModel = {
  /** A value indicating whether the Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionResultNotificationModel provided in the request
    should overwrite those configured in the Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionModel. */
  overrideResultNotifications?: boolean;
  /** Collection of Microsoft.WebSec.Director.Api.V2.Models.ScanDefinitionResultNotificationModel for the Microsoft.WebSec.Director.Api.V2.Models.ScanRequestModel. */
  resultNotifications?: ScanDefinitionResultNotificationModel[];
  /** Unique identifier of the Scan represented in the instance. */
  scanDefinitionId: string;
  /** Indicates whether the Callback URL process should be skipped once the Scan Result is ready. */
  skipCallback?: boolean;
};
export const {
  useGetDetectionsQuery,
  useGetRegionsQuery,
  useGetResultNotificationsQuery,
  useGetScanDefinitionQuery,
  usePatchScanDefinitionMutation,
  usePutScanDefinitionMutation,
  useGetScanDefinitionsQuery,
  usePostScanDefinitionMutation,
  usePostScanInstanceMutation,
  usePutScanInstanceMutation,
  useGetScanRequestQuery,
  usePostScanRequestMutation,
} = injectedRtkApi;
