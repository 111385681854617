import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  IStackTokens,
  Panel,
  PanelType,
  Stack,
} from "@fluentui/react";
import { ScanDefinitionCredentialModel } from "../../store/directorApi.generated";
import "../layout/Entity.scss";
import React from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { withTheme } from "@rjsf/core";
import { Theme as FluentUITheme } from "@rjsf/fluent-ui";
import basicAuthSchema from '../../schemas/BasicAuthenticationCredential.v1.0.json';
import apiTokenAuthSchema from '../../schemas/ApiTokenCredential.v1.0.json';
import azureAadAuthSchema from '../../schemas/AzureAdApplicationCredential.v1.0.json';
import microsoftAccountAuthSchema from '../../schemas/MicrosoftAccountCredential.v1.0.json';

const Form = withTheme(FluentUITheme);

interface IScanDefinitionCredentialFormProps {
  detail?: ScanDefinitionCredentialModel;
  onRequestOptionChange: (detail?: ScanDefinitionCredentialModel) => void;
}

interface IScanConfigurationUrlMap {
  key: string;
  url: string;
}

export const ScanDefinitionCredentialForm = (
  props: IScanDefinitionCredentialFormProps
) => {
  const [supportedConfigurationSchema, setSupportedConfigurationSchema] =
    React.useState({});

  React.useEffect(() => {
    (async () => {
      if (
        !selectedAuthConfigUrlMap ||
        !selectedAuthConfigUrlMap.url ||
        !props.detail
      ) {
        return;
      }

      switch (selectedAuthConfigUrlMap.key) {
        case "none": {
          setSupportedConfigurationSchema({});
          break;
        }
        case "basic": {
          setSupportedConfigurationSchema(basicAuthSchema);
          break;
        }
        case "apiToken": {
          setSupportedConfigurationSchema(apiTokenAuthSchema);
          break;
        }
        case "microsoftAccount": {
          setSupportedConfigurationSchema(microsoftAccountAuthSchema);
          break;
        }
        case "azureAdApplication": {
          setSupportedConfigurationSchema(azureAadAuthSchema);
          break;
        }

      }
    })();
  }, [props.detail?.kind]);

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
  };
  const authenticationOptions: IDropdownOption[] = [
    { key: "none", text: "None" },
    { key: "basic", text: "BasicAuthentication" },
    { key: "apiToken", text: "ApiToken" },
    { key: "microsoftAccount", text: "MicrosoftAccount" },
    { key: "azureAdApplication", text: "AzureAdApplication" },
  ];
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const authenticationConfigurationUrls: IScanConfigurationUrlMap[] =
    authenticationOptions.map((option) => ({
      key: option.key as string,
      url: `https://websecdirectorprod.blob.core.windows.net/schemas/${option.text}Credential.v1.0.json`,
    }));

  const iconStackTokens: IStackTokens = {
    childrenGap: 30,
    maxWidth: 500,
  };

  let selectedAuthConfigUrlMap = authenticationConfigurationUrls.find(
    (c) => c.key === props.detail?.kind
  );

  return (
    <>
      <Stack.Item tokens={iconStackTokens}>
        <div className="title authType">Authentication Method Selection</div>
        <Stack horizontal>
          <Dropdown
            placeholder="Select an authentication type"
            options={authenticationOptions}
            styles={dropdownStyles}
            onChange={(event, option) => {
              props.onRequestOptionChange(
                (option?.key as string) === "none"
                  ? undefined
                  : {
                    ...props.detail,
                    kind: option?.key as string,
                    configuration: undefined,
                  }
              );
            }}
            selectedKey={props.detail?.kind}
          />
          {props.detail && supportedConfigurationSchema && (
            <DefaultButton text="Add Configuration" onClick={openPanel} />
          )}
        </Stack>
        <span className="textField-description">
          Authentication mechanism used for scanning if required.
        </span>

        <Panel
          isOpen={isOpen}
          onDismiss={dismissPanel}
          isLightDismiss
          type={PanelType.medium}
        >
          <Form
            schema={supportedConfigurationSchema}
            uiSchema={{
              "ui:submitButtonOptions": {
                norender: false,
                props: {
                  className: "detectionConfigSubmit",
                  disabled: false,
                },
                submitText: "Save",
              },
              "ui:title": "",
            }}
            formData={props.detail?.configuration}
            onSubmit={(e) => {
              props.onRequestOptionChange({
                ...props.detail,
                configuration: e.formData,
              });
              dismissPanel();
            }}
          />
        </Panel>
      </Stack.Item>
    </>
  );
};
