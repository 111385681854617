import {
  Dropdown,
  IButtonStyles,
  IconButton,
  IDropdownOption,
  IDropdownStyles,
  IStackTokens,
  Stack,
  TextField,
} from "@fluentui/react";
import {
  RequestHeaderModel,
  ScanDefinitionCredentialModel,
  ScanDefinitionRequestOptionsModel,
} from "../../store/directorApi.generated";
import { ScanDefinitionHeader } from "../ScanDefinition/ScanDefinitionHeader";
import "../layout/Entity.scss";
import { topEntityStackStyle } from "../styling";
import { ScanDefinitionCredentialForm } from "../ScanDefinition/ScanDefinitionCredentialForm";

interface IRequestOptionFormProps {
  detail?: ScanDefinitionRequestOptionsModel;
  onRequestOptionChange: (detail: ScanDefinitionRequestOptionsModel) => void;
}
export const RequestOptionForm = (props: IRequestOptionFormProps) => {
  if (props.detail == undefined) {
    return <div></div>;
  }
  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
  };

  const deviceOptions: IDropdownOption[] = [
    { key: "desktop", text: "Desktop" },
    { key: "mobile", text: "Mobile" },
    { key: "tablet", text: "Tablet" },
  ];
  const iconStackTokens: IStackTokens = {
    childrenGap: 30,
    maxWidth: 500,
  };
  const iconButtonStyles: Partial<IButtonStyles> = {
    root: { marginBottom: -3 },
  };
  return (
    <>
      <Stack.Item tokens={iconStackTokens}>
        <ScanDefinitionCredentialForm
          detail={props.detail.authentication}
          onRequestOptionChange={(credential) => {
            props.onRequestOptionChange({
              ...props.detail,
              authentication: credential,
            });
          }}
        />
        <Dropdown
          placeholder="Select a device kind"
          label="Device kind Selection"
          options={deviceOptions}
          styles={dropdownStyles}
          onChange={(event, option) => {
            props.onRequestOptionChange({
              ...props.detail,
              deviceKind: option?.key as string,
            });
          }}
          selectedKey={props.detail.deviceKind}
        />
        <span className="textField-description">
          Type of device to emulate on each request for scanning. (Optional)
        </span>
        <div className="title">Request Headers</div>
        <Stack verticalFill verticalAlign="center" styles={topEntityStackStyle}>
          {props.detail.headers?.map((header, num) => (
            <ScanDefinitionHeader
              key={num}
              headers={props.detail?.headers!}
              index={num}
              onHeaderChange={(newHeaders) => {
                props.onRequestOptionChange({
                  ...props.detail,
                  headers: newHeaders,
                });
              }}
            />
          ))}
          <div className="addIconStack">
            <div className="addIconStackItemLabel">Additional Header</div>
            <div className="addIconStackItem">
              <IconButton
                id={"addOwnerButton"}
                iconProps={{ iconName: "Add" }}
                title="Additional Header"
                ariaLabel="Add Header Icon"
                onClick={() => {
                  let addedHeader: RequestHeaderModel[];
                  if (props.detail?.headers == undefined) {
                    addedHeader = [{ name: "", secretName: "", value: "" }];
                  } else {
                    addedHeader = [
                      ...props.detail.headers,
                      { name: "", secretName: "", value: "" },
                    ];
                  }
                  props.onRequestOptionChange({
                    ...props.detail,
                    headers: addedHeader,
                  });
                }}
                styles={iconButtonStyles}
              />
            </div>
          </div>
        </Stack>
        <span className="textField-description">
          Additional request headers to be appended on each web request if any.
        </span>

        <TextField
          name="userAgent"
          label="User Agent"
          description="Custom User-Agent string to use for each web request."
          onChange={(event) => {
            props.onRequestOptionChange({
              ...props.detail,
              userAgent: event.currentTarget.value,
            });
          }}
          value={props.detail.userAgent}
        />
      </Stack.Item>
    </>
  );
};
