import { DefaultButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { intializeAuth, msalInstance } from "../../auth";
import { Docs } from "../Docs/Docs";
import Home from "../Home";
import "../Home.scss";
import WelcomeMessage from "./WelcomeMessage";

function Welcome() {
  const [currentComp, setCurrentComp] = useState("welcomeMessage");
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const showDocsPage = () => {
    return setCurrentComp("docs");
  };

  const showWelcomeMessage = () => {
    return setCurrentComp("welcomeMessage");
  };
  useEffect(() => {
    intializeAuth().then(() => setLoggedIn(true));
    document.title = "Welcome - WebSec Portal";
  });
  const handleState = () => {
    let accounts = msalInstance.getAllAccounts();

    if (accounts && accounts[0]) {
      return <Home />;
    } else {
      if (currentComp === "welcomeMessage") {
        return <WelcomeMessage onClick={showDocsPage} />;
      } else if (currentComp === "docs") {
        return (
          <div className="docs-wrapper">
            <DefaultButton onClick={() => showWelcomeMessage()}>
              Show Welcome Message
            </DefaultButton>
            <Docs />
          </div>
        );
      }
    }
  };

  return <>{handleState()}</>;
}

export default Welcome;
