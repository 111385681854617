import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { directorApi } from "../store/directorApi.generated";
import { reporterApi } from "../store/reporterApi.generated";

export const store = configureStore({
  reducer: {
    [directorApi.reducerPath]: directorApi.reducer,
    [reporterApi.reducerPath]: reporterApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      directorApi.middleware,
      reporterApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
