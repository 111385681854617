import React from "react";
import { useTitle } from "../../HeaderUpdator";
import { useGetDetectionsQuery } from "../../store/directorApi.generated";
import { Entity } from "../layout/Entity";
import "./DetectionList.scss";

export const DetectionList = () => {
  useTitle("Supported Detections");
  const { data } = useGetDetectionsQuery();
  if (!data || !data.values) {
    return <h2>Loading detections...</h2>;
  }

  if (data.values) {
    return (
      <>
        <h2>Supported Detections</h2>
        <div className="detectionContainer">
          <ul id="supportedDetections">
            {data.values.map((detection) => (
              <li aria-labelledby="supportedDetections">
                <Entity
                  children={[
                    { left: "Detection Name:", right: detection.name },
                    {
                      left: "Description:",
                      right: detection.description ?? "Not Configured",
                    },
                    ...(detection.configurationSchemas?.flatMap((e, index) => [
                      {
                        left: `Configuration Version: `,
                        right: e.configurationSchemaVersion ?? "Not Configured",
                      },
                      {
                        left: `Configuration Url:`,
                        right: e.configurationSchemaUrl ?? "Not Configured",
                      },
                    ]) ?? []),
                  ]}
                />
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  } else {
    return <h2>Failed to parse detections.</h2>;
  }
};
