import { getTheme } from "@fluentui/react";
import React, { ReactNode, useEffect } from "react";
import "./App.scss";
import { intializeAuth } from "./auth";
import { OperationCommandBar } from "./components/layout/OperationCommandBar";

const theme = getTheme();

function App({ children }: { children?: ReactNode }) {
  const [initialized, setInitialized] = React.useState(false);
  useEffect(() => {
    intializeAuth().then(() => setInitialized(true));
  });

  let logginContent = (
    <>
      <OperationCommandBar isSignedIn={true} />
      <div
        className="mainContainer"
        style={{
          background: theme.semanticColors.bodyStandoutBackground,
          minHeight: 800,
        }}
      >
        <div className="childrenContainer">{children}</div>
      </div>
    </>
  );
  return (
    <>
      {logginContent}
      <ul id="footerLinkList">
        <li className="footer"> © 2024 Microsoft</li>
        <li className="footer"><a

          href="https://go.microsoft.com/fwlink/?LinkID=206977"
        >
          Terms of Use
        </a></li>
        <li className="footer"> <a

          href="http://go.microsoft.com/fwlink/?LinkId=521839"
        >
          Privacy & Cookies
        </a></li>
        <li className="footer"><a

          href="https://www.microsoft.com/trademarks"
        >
          Trademarks
        </a></li>
      </ul>
    </>
  );
}

export default App;
