import { directorApi as original } from "./directorApi.generated";

export const directorApi = original.enhanceEndpoints({
  addTagTypes: ["ScanDefinition"],
  endpoints: {
    putScanDefinition: { invalidatesTags: ["ScanDefinition"] },
    getScanDefinition: { providesTags: ["ScanDefinition"] },
  },
});

export const {
  useGetDetectionsQuery,
  useGetRegionsQuery,
  useGetResultNotificationsQuery,
  useGetScanDefinitionQuery,
  usePatchScanDefinitionMutation,
  usePutScanDefinitionMutation,
  useGetScanDefinitionsQuery,
  usePostScanDefinitionMutation,
  usePostScanInstanceMutation,
  usePutScanInstanceMutation,
  useGetScanRequestQuery,
  usePostScanRequestMutation,
} = directorApi;
