import {
  Checkbox,
  ChoiceGroup,
  getTheme,
  IButtonStyles,
  IChoiceGroupOption,
  IconButton,
  IStackStyles,
  IStackTokens,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
  Toggle,
} from "@fluentui/react";

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseConfig } from "../../Configurations/config";
import { useTitle } from "../../HeaderUpdator";
import {
  getGuidInputErrorMessage,
  getMaxScanRunTimeInputErrorMessage,
  getServiceTreeIdErrorMessage,
  getUrlInputErrorMessage,
} from "../../inputValidator";
import {
  useGetRegionsQuery,
  usePostScanDefinitionMutation,
  usePostScanRequestMutation,
  usePutScanDefinitionMutation,
} from "../../store/apiEnhancer";
import {
  PostScanDefinitionApiArg,
  PutScanDefinitionApiArg,
  ScanDefinitionDetectionModel,
  ScanDefinitionModel,
  ScanDefinitionOwnerModel,
  ScanDefinitionPostModel,
  ScanDefinitionPutModel,
  ScanDefinitionRequestOptionsModel,
  ScanDefinitionResultNotificationModel,
} from "../../store/directorApi.generated";
import { DetectionDetailForm } from "../Detection/DetectionDetailForm";
import "../layout/Entity.scss";
import { RequestOptionForm } from "../RequestOption/RequestOptionForm";
import { ResultNotificationForm } from "../ResultNotification/ResultNotificationForm";
import { outerEntityStackStyle, topEntityStackStyle } from "../styling";
import { EntityStringList } from "./EntityStringList";
import { ScanDefinitionOwner } from "./ScanDefinitionOwner";

const stackTokens: IStackTokens = {
  childrenGap: 0,
  maxWidth: 500,
};
const subStackTokens: IStackTokens = {
  childrenGap: 0,
  maxWidth: 500,
  padding: 30,
};

const scanRequestOptions: IChoiceGroupOption[] = [
  { key: "immediately", text: "Immediately" },
  { key: "onschedule", text: "On Schedule" },
];

type ScanDefinitionCreationProps = {
  scanDefinitionToEdit?: ScanDefinitionModel;
};
type ScanDefinitionErrorFeeds = {
  [key: string]: string[];
};
type ScanDefinitionCreationErrorProps = {
  errors: ScanDefinitionErrorFeeds;
  type: string;
  title: string;
  status: string;
};

export const ScanDefinitionCreation: React.FunctionComponent<
  ScanDefinitionCreationProps
> = (props: ScanDefinitionCreationProps) => {
  const [scanDefinitionPost, postResult] = usePostScanDefinitionMutation();
  const [scanDefinitionPut, putResult] = usePutScanDefinitionMutation();
  const [scanRequestPost] = usePostScanRequestMutation();
  const iconButtonStyles: Partial<IButtonStyles> = {
    root: { marginBottom: -1 },
  };

  const { data: supportedRegions } = useGetRegionsQuery();
  let navigate = useNavigate();
  useTitle("Create Scan Definition");

  const addIconProps = { iconName: "Add" };

  const [currentScanDefinition, setScanDefinition] =
    React.useState<ScanDefinitionPutModel>(
      props.scanDefinitionToEdit
        ? {
            applicationId: props.scanDefinitionToEdit.applicationId,
            isActive: props.scanDefinitionToEdit.isActive,
            id: props.scanDefinitionToEdit.id,
            title: props.scanDefinitionToEdit.title,
            urls: props.scanDefinitionToEdit.urls,
            owners: props.scanDefinitionToEdit.owners,
            serviceTreeId: props.scanDefinitionToEdit.serviceTreeId,
            detections: props.scanDefinitionToEdit.detections,
            regions: props.scanDefinitionToEdit.regions,
            maxScanRuntimeInMinutes:
              props.scanDefinitionToEdit.maxScanRuntimeInMinutes,
            nextScheduledRun: props.scanDefinitionToEdit.nextScheduledRun,
            resultNotifications: props.scanDefinitionToEdit.resultNotifications,
            recurrenceScheduleInHours:
              props.scanDefinitionToEdit.recurrenceScheduleInHours,
            requestOptions: props.scanDefinitionToEdit.requestOptions ?? {
              authentication: undefined,
              deviceKind: "",
              headers: [
                { name: undefined, secretName: undefined, value: undefined },
              ],
              userAgent: undefined,
            },
          }
        : {
            isActive: true,
            title: "",
            urls: [""],
            owners: [{ mailNickname: "", objectType: "" }],
            serviceTreeId: "",
            detections: [{}],
            regions: [],
            nextScheduledRun: undefined,
            resultNotifications: [{ audiences: [""] }],
            maxScanRuntimeInMinutes: 60,
            requestOptions: {
              authentication: undefined,
              deviceKind: "",
              headers: [
                { name: undefined, secretName: undefined, value: undefined },
              ],
              userAgent: undefined,
            },
          }
    );
  const [onSchedule, setOnSchedule] = React.useState(
    (props.scanDefinitionToEdit &&
      props.scanDefinitionToEdit.nextScheduledRun) ??
      false
  );
  const [hasGroupOwner, setHasGroupOwner] = React.useState(true);
  const [configProvidedForAuth, setConfigProvidedForAuth] =
    React.useState(true);

  if (!supportedRegions?.values) {
    return (
      <div>
        <Spinner label="Loading..." />
      </div>
    );
  }
  if (typeof currentScanDefinition.detections === "undefined") {
    throw new Error("Invalid state");
  }

  // Mutating styles definition
  const containerStackStyles: IStackStyles = {
    root: {
      height: "800",
      width: "1100",
    },
  };

  return (
    <>
      <form
        onSubmit={async (e) => {
          if (
            currentScanDefinition.serviceTreeId !==
            baseConfig.defaultServiceTreeId
          ) {
            e.preventDefault();
            if (
              currentScanDefinition.owners.findIndex(
                (owner) => owner.objectType === "group"
              ) === -1
            ) {
              setHasGroupOwner(false);
            }
            if (
              currentScanDefinition.requestOptions?.authentication?.kind &&
              !currentScanDefinition.requestOptions?.authentication
                ?.configuration
            ) {
              setConfigProvidedForAuth(false);
            } else {
              setHasGroupOwner(true);
              setConfigProvidedForAuth(true);
              let modifyingScanDefinition = { ...currentScanDefinition };
              if (
                // Set Detections property to be undefined instead of an array with an empty detection
                currentScanDefinition.detections &&
                currentScanDefinition.detections.length === 1 &&
                !currentScanDefinition.detections[0].id
              ) {
                modifyingScanDefinition.detections = undefined;
              }

              if (
                // Set Header array to be undefined if none of the header feeds are filled
                modifyingScanDefinition.requestOptions &&
                modifyingScanDefinition.requestOptions.headers &&
                modifyingScanDefinition.requestOptions.headers.length === 1 &&
                !modifyingScanDefinition.requestOptions.headers[0].name &&
                !modifyingScanDefinition.requestOptions.headers[0].secretName &&
                !modifyingScanDefinition.requestOptions.headers[0].value
              ) {
                modifyingScanDefinition.requestOptions.headers = undefined;
              }

              if (props.scanDefinitionToEdit) {
                let param: PutScanDefinitionApiArg = {
                  scanDefinitionId: props.scanDefinitionToEdit.id,
                  scanDefinitionPutModel: modifyingScanDefinition,
                };
                let modified = await scanDefinitionPut(param).unwrap();
                if (modified.id) {
                  navigate(
                    `/scanDefinitions/${modified.id}?scanDefinitionType=edited`
                  );
                }
              } else {
                let param: PostScanDefinitionApiArg = {
                  scanDefinitionPostModel: modifyingScanDefinition,
                };
                let newScanDefinition = await scanDefinitionPost(
                  param
                ).unwrap();

                if (newScanDefinition.id) {
                  if (onSchedule) {
                    navigate(
                      `/scanDefinitions/${newScanDefinition.id}?scanDefinitionType=created`
                    );
                  } else {
                    // Submit scan request for the newly created scan definition if it's set to be on demand
                    let createdScanRequest = await scanRequestPost({
                      scanRequestPostModel: {
                        scanDefinitionId: newScanDefinition.id,
                      },
                    }).unwrap();
                    navigate(
                      "/scanRequests/" +
                        createdScanRequest.id +
                        "?scanDefinitionType=created"
                    );
                  }
                }
              }
            }
          }
        }}
      >
        <div className="title">General Information:</div>
        <Stack
          wrap
          verticalFill
          verticalAlign="center"
          styles={outerEntityStackStyle}
          tokens={stackTokens}
        >
          <Stack
            verticalFill
            verticalAlign="center"
            styles={outerEntityStackStyle}
            tokens={stackTokens}
          >
            <Toggle
              label="Is Active (False means the scan is disabled)"
              defaultChecked={currentScanDefinition.isActive}
              onText="True"
              offText="False"
              onChange={(event, checked) => {
                setScanDefinition({
                  ...currentScanDefinition,
                  isActive: checked,
                });
              }}
            />
            <TextField
              required
              name="title"
              label="Title"
              description="Title of the Scan Definition"
              onChange={(event) => {
                setScanDefinition({
                  ...currentScanDefinition,
                  title: event.currentTarget.value,
                });
              }}
              value={currentScanDefinition.title}
            />
            <TextField
              required
              label="Service Tree Id"
              description="Service Tree identifier associated with the targeted service."
              onChange={(event) => {
                setScanDefinition({
                  ...currentScanDefinition,
                  serviceTreeId: event.currentTarget.value,
                });
              }}
              onGetErrorMessage={getServiceTreeIdErrorMessage}
              value={currentScanDefinition.serviceTreeId}
            />
            <div className="title">AAD Group Identity</div>
            <Stack
              verticalFill
              verticalAlign="center"
              tokens={subStackTokens}
              styles={topEntityStackStyle}
            >
              {currentScanDefinition.owners.map((owner, num) => (
                <ScanDefinitionOwner
                  key={num}
                  index={num}
                  owners={currentScanDefinition.owners}
                  onOwnerChange={(newOwners: ScanDefinitionOwnerModel[]) => {
                    setScanDefinition({
                      ...currentScanDefinition,
                      owners: newOwners,
                    });
                  }}
                />
              ))}
              <div className="addIconStack">
                <div className="addIconStackItemLabel">
                  Additional Owner Identity
                </div>
                <div className="addIconStackItem">
                  <IconButton
                    id={"addOwnerButton"}
                    iconProps={addIconProps}
                    title="Additional Owner"
                    ariaLabel="Add Owner Icon"
                    onClick={() => {
                      let addedOwners: ScanDefinitionOwnerModel[] = [
                        ...currentScanDefinition.owners,
                        { mailNickname: "", objectType: "" },
                      ];

                      setScanDefinition({
                        ...currentScanDefinition,
                        owners: addedOwners,
                      });
                    }}
                    styles={iconButtonStyles}
                  />
                </div>
              </div>
            </Stack>
            <span className="textField-description">
              Please keep at least one 'Group' owner type for better scan
              definition management.
            </span>
            <div className="title">URLs</div>
            <Stack
              verticalFill
              verticalAlign="center"
              tokens={subStackTokens}
              styles={topEntityStackStyle}
            >
              {currentScanDefinition.urls.map((url, num) => (
                <EntityStringList
                  key={num}
                  title="Url"
                  onEntityChange={(newUrls: string[]) => {
                    let newScanDefinition = { ...currentScanDefinition };
                    newScanDefinition.urls = newUrls;
                    setScanDefinition(newScanDefinition);
                  }}
                  index={num}
                  entities={currentScanDefinition.urls}
                  getErrorMessage={getUrlInputErrorMessage}
                />
              ))}
            </Stack>
            <div className="addIconStack">
              <div className="addIconStackItemLabel">Additional URL</div>
              <div className="addIconStackItem">
                <IconButton
                  id={"addUrlButton"}
                  iconProps={addIconProps}
                  title="Add Url"
                  ariaLabel="Add Url Icon"
                  onClick={() => {
                    let newUrls = [...currentScanDefinition.urls];
                    newUrls.push("");
                    setScanDefinition({
                      ...currentScanDefinition,
                      urls: newUrls,
                    });
                  }}
                  styles={iconButtonStyles}
                />
              </div>
            </div>
            <Stack verticalFill verticalAlign="center" tokens={stackTokens}>
              <TextField
                name="applicationId"
                label="Application Id"
                description="AAD identifier of the application for the scan definition. Required if using an Application Identity to programmatically execute this scan via the WebSec API."
                onChange={(event) => {
                  setScanDefinition({
                    ...currentScanDefinition,
                    applicationId: event.currentTarget.value,
                  });
                }}
                value={currentScanDefinition.applicationId}
                onGetErrorMessage={getGuidInputErrorMessage}
              />
            </Stack>
          </Stack>

          <Stack
            verticalFill
            verticalAlign="center"
            tokens={stackTokens}
          ></Stack>
          <Stack horizontal>
            <div className="title">Detections:</div>
            <Link key={"detectionLink"} to={"/detections"} target="_blank">
              View all supported detections
            </Link>
          </Stack>
          <Stack
            verticalFill
            verticalAlign="center"
            styles={outerEntityStackStyle}
            tokens={stackTokens}
          >
            <Stack
              verticalFill
              verticalAlign="center"
              tokens={subStackTokens}
              styles={topEntityStackStyle}
            >
              {currentScanDefinition.detections.map((detection, num) => (
                <DetectionDetailForm
                  key={detection.id}
                  onDetectionChange={(
                    newDetection: ScanDefinitionDetectionModel
                  ): void => {
                    updateEntity(
                      currentScanDefinition,
                      num,
                      "detections",
                      newDetection,
                      setScanDefinition
                    );
                  }}
                  onDeleteDetectionClick={() => {
                    deleteEntity(
                      currentScanDefinition,
                      num,
                      "detections",
                      setScanDefinition
                    );
                  }}
                  detail={detection}
                />
              ))}
              <div className="addIconStack" key="additionalDetection">
                <div className="addIconStackItemLabel">
                  Additional Detection
                </div>
                <div className="addIconStackItem">
                  <IconButton
                    id={"addDetectionButton"}
                    iconProps={addIconProps}
                    title="Add Detection"
                    ariaLabel="Add Detection Icon"
                    onClick={() => {
                      let addedDetections: ScanDefinitionDetectionModel[] = [
                        ...currentScanDefinition.detections!,
                        {},
                      ];
                      let newScanDefinition = {
                        ...currentScanDefinition,
                        detections: addedDetections,
                      };
                      setScanDefinition(newScanDefinition);
                    }}
                    styles={iconButtonStyles}
                  />
                </div>
              </div>
            </Stack>
            <span className="textField-description">
              Choose specific Detections or leave this section blank to use
              default settings.
            </span>
            <span className="textField-description">
              Defaults may not provide comprehensive coverage but may be
              sufficient for simple web sites.
            </span>

            <TextField
              key="maxScanRuntime"
              label="Max Scan Runtime (Minutes)"
              description="Maximum amount of time a scan is allowed to run before timeout, in minutes. (Value can be between 1 - 120)"
              onChange={(e) => {
                if (e.currentTarget.value) {
                  setScanDefinition({
                    ...currentScanDefinition,
                    maxScanRuntimeInMinutes: parseInt(e.currentTarget.value),
                  });
                } else {
                  setScanDefinition({
                    ...currentScanDefinition,
                    maxScanRuntimeInMinutes: undefined,
                  });
                }
              }}
              onGetErrorMessage={getMaxScanRunTimeInputErrorMessage}
              value={currentScanDefinition.maxScanRuntimeInMinutes?.toString()}
            />
          </Stack>

          <div className="title">Scheduling:</div>
          <Stack
            verticalFill
            verticalAlign="center"
            styles={outerEntityStackStyle}
            tokens={stackTokens}
          >
            <Stack verticalFill verticalAlign="center" tokens={stackTokens}>
              <ChoiceGroup
                defaultSelectedKey={onSchedule ? "onschedule" : "immediately"}
                options={scanRequestOptions}
                onChange={() => {
                  if (onSchedule) {
                    setScanDefinition({
                      ...currentScanDefinition,
                      nextScheduledRun: undefined,
                    });
                  } else {
                    setScanDefinition({
                      ...currentScanDefinition,
                      nextScheduledRun: new Date().toISOString(),
                    });
                  }
                  setOnSchedule(!onSchedule);
                }}
                label="Run Scan"
                required={true}
              />
              {onSchedule && (
                <TextField
                  required
                  label="Next Scheduled Run"
                  description="Timestamp, in UTC, of the next scan invocation."
                  value={currentScanDefinition.nextScheduledRun}
                  onChange={(e) => {
                    setScanDefinition({
                      ...currentScanDefinition,
                      nextScheduledRun: e.currentTarget.value,
                    });
                  }}
                />
              )}
            </Stack>
            <Stack verticalFill verticalAlign="center" tokens={stackTokens}>
              <TextField
                label="Recurrence Schedule Interval (Hours)"
                description="Number of hours to wait between automatic scan requests, if the definition should run on a schedule. (Value can be between 12 - 8760)"
                onChange={(event) => {
                  setScanDefinition({
                    ...currentScanDefinition,
                    recurrenceScheduleInHours: parseInt(
                      event.currentTarget.value
                    ),
                  });
                }}
                value={
                  currentScanDefinition.recurrenceScheduleInHours
                    ? currentScanDefinition.recurrenceScheduleInHours.toString()
                    : undefined
                }
              />
            </Stack>
            <div className="regionCheckbox">
              <span className="title">Region</span>
              <fieldset className="legendContainer">
                <legend className="legendMessage">
                  Pick a region to run a scan.
                </legend>
                {supportedRegions.values.map((region, index) => (
                  <Checkbox
                    key={index}
                    required={currentScanDefinition.regions?.length === 0}
                    label={
                      region.isDefault
                        ? `${region.name} (Default)`
                        : region.name
                    }
                    onChange={(e, checked) => {
                      if (checked) {
                        let newRegions = [...currentScanDefinition.regions!];
                        newRegions.push(region.id);
                        setScanDefinition({
                          ...currentScanDefinition,
                          regions: newRegions,
                        });
                      } else {
                        let newRegions = [...currentScanDefinition.regions!];
                        setScanDefinition({
                          ...currentScanDefinition,
                          regions: newRegions.filter((r) => r !== region.id),
                        });
                      }
                    }}
                    checked={currentScanDefinition.regions?.includes(region.id)}
                  />
                ))}
              </fieldset>
            </div>
          </Stack>
          <Stack horizontal>
            <div className="title resultNotification">
              Result Notifications:
            </div>

            <Link
              key={"resultNotificationLink"}
              target="_blank"
              to={"/resultNotifications"}
            >
              View all supported result notifications
            </Link>
          </Stack>

          <Stack
            verticalFill
            verticalAlign="center"
            tokens={subStackTokens}
            styles={topEntityStackStyle}
          >
            {currentScanDefinition.resultNotifications?.map((result, num) => (
              <ResultNotificationForm
                key={num}
                detail={result}
                onResultNotificationChange={(newResultNotification) => {
                  updateEntity(
                    currentScanDefinition,
                    num,
                    "resultNotifications",
                    newResultNotification,
                    setScanDefinition
                  );
                }}
                onDeleteResultNotificationClick={() => {
                  deleteEntity(
                    currentScanDefinition,
                    num,
                    "resultNotifications",
                    setScanDefinition
                  );
                }}
              />
            ))}
          </Stack>
          <div className="addIconStack">
            <div className="addIconStackItemLabel">
              Additional Result Notification
            </div>
            <div className="addIconStackItem">
              <IconButton
                id="addResultNotificationButton"
                iconProps={addIconProps}
                title="Add Result Notification"
                ariaLabel="Add Owner Icon"
                onClick={() => {
                  let resultNotifications: ScanDefinitionResultNotificationModel[] =
                    [
                      ...(currentScanDefinition.resultNotifications ?? []),
                      { audiences: [], configurationSchemaVersion: "" },
                    ];

                  setScanDefinition({
                    ...currentScanDefinition,
                    resultNotifications,
                  });
                }}
                styles={iconButtonStyles}
              />
            </div>
          </div>
        </Stack>
        <Stack verticalFill verticalAlign="center" tokens={stackTokens}>
          <div className="title requestOption">Request Options:</div>
          <RequestOptionForm
            detail={currentScanDefinition.requestOptions}
            onRequestOptionChange={(
              detail: ScanDefinitionRequestOptionsModel
            ) => {
              setScanDefinition({
                ...currentScanDefinition,
                requestOptions: detail,
              });
            }}
          />
        </Stack>
        <PrimaryButton
          key="Submit"
          type="submit"
          size={1}
          className="submitButton"
        >
          Submit
        </PrimaryButton>
      </form>
      {postResult.error && "status" in postResult.error && (
        <MessageBar
          className="errorMessageBanner"
          messageBarType={MessageBarType.error}
          isMultiline={false}
          dismissButtonAriaLabel="Close"
        >
          {GetErrorMessages(
            postResult.error.data as ScanDefinitionCreationErrorProps
          )}
        </MessageBar>
      )}
      {putResult.error && "status" in putResult.error && (
        <MessageBar
          className="errorMessageBanner"
          messageBarType={MessageBarType.error}
          isMultiline={false}
          dismissButtonAriaLabel="Close"
        >
          {GetErrorMessages(
            putResult.error.data as ScanDefinitionCreationErrorProps
          )}
        </MessageBar>
      )}
      {!hasGroupOwner && (
        <MessageBar
          className="errorMessageBanner"
          messageBarType={MessageBarType.error}
          isMultiline={false}
          dismissButtonAriaLabel="Close"
        >
          {
            "Please add at least one 'Group' owner type for the scan definition."
          }
        </MessageBar>
      )}
      {!configProvidedForAuth && (
        <MessageBar
          className="errorMessageBanner"
          messageBarType={MessageBarType.error}
          isMultiline={false}
          dismissButtonAriaLabel="Close"
        >
          {"Please add configuration for the selected authentication kind."}
        </MessageBar>
      )}
    </>
  );
};

type ConfigurationProperties = "detections" | "resultNotifications";
function updateEntity(
  currentScanDefinition: ScanDefinitionPutModel,
  num: number,
  propertyToUpdate: ConfigurationProperties,
  newEntity:
    | ScanDefinitionDetectionModel
    | ScanDefinitionResultNotificationModel,
  setScanDefinition: React.Dispatch<
    React.SetStateAction<ScanDefinitionPutModel>
  >
) {
  let newEntities = [...currentScanDefinition[propertyToUpdate]!];
  newEntities[num] = newEntity;
  setScanDefinition({
    ...currentScanDefinition,
    [propertyToUpdate]: newEntities,
  });
}

function deleteEntity(
  currentScanDefinition: ScanDefinitionPutModel,
  num: number,
  propertyToDelete: ConfigurationProperties,
  setScanDefinition: React.Dispatch<
    React.SetStateAction<ScanDefinitionPutModel>
  >
) {
  let newEntities: ScanDefinitionDetectionModel[] = [
    ...currentScanDefinition[propertyToDelete]!,
  ];
  newEntities.splice(num, 1);
  let newScanDefinition: ScanDefinitionPostModel = {
    ...currentScanDefinition,
    [propertyToDelete]: newEntities,
  };
  setScanDefinition(newScanDefinition);
}

function GetErrorMessages(data: ScanDefinitionCreationErrorProps): string {
  let result = "";

  for (const key in data.errors) {
    result += data.errors[key][0] + " \n";
  }
  return result;
}
