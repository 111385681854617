import { PublicClientApplication } from "@azure/msal-browser";
import {
  reporterApiConfig,
  directorApiConfig,
  msalBaseConfig,
} from "./Configurations/config";

const msalConfig = {
  auth: {
    clientId: msalBaseConfig.portalApplicationId,
    authority:
      "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: msalBaseConfig.redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
export const msalInstance = new PublicClientApplication(msalConfig);
export let accounts = msalInstance.getAllAccounts();

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const getDirectorLoginRequest = () => {
  if (!accounts || !accounts[0]) {
    accounts = msalInstance.getAllAccounts();
  }

  return {
    scopes: [`api://${directorApiConfig.applicationId}/access_as_user`],
    account: accounts[0],
  };
};

export const getReporterLoginRequest = () => {
  if (!accounts || !accounts[0]) {
    accounts = msalInstance.getAllAccounts();
  }
  console.log("In GetReporterLoginRequeswt");
  return {
    scopes: [`api://${reporterApiConfig.applicationId}/access_as_user`],
    account: accounts[0],
  };
};

// It gets called by a sign in component
// Has to be called in every app start
export const intializeAuth = () => {
  return msalInstance.handleRedirectPromise();
};
