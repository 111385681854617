import {
  DefaultButton,
  Dropdown,
  IButtonStyles,
  IconButton,
  IDropdownOption,
  IDropdownStyles,
  IStackTokens,
  Panel,
  PanelType,
  Stack,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { withTheme } from "@rjsf/core";
import { Theme as FluentUITheme } from "@rjsf/fluent-ui";
import React from "react";
import { ScanDefinitionDetectionModel } from "../../store/directorApi.generated";
import { useGetDetectionsQuery } from "../../store/apiEnhancer";
import { subEntityStackStyle } from "../styling";

// Import detection schema files.
import apiFuzzingSchema from "../../schemas/Restler.v8.5.json";
import consentModalSchema from "../../schemas/ConsentModalDetection.v1.0.json";
import contentSecurityPolicySchema from "../../schemas/ContentSecurityPolicyDetection.v1.0.json";
import cookieChangeSchema from "../../schemas/CookieChangeDetection.v1.0.json";
import cookieCollectionSchema from "../../schemas/CookieCollectionDetection.v1.0.json";
import httpResponseSplittingSchema from "../../schemas/HttpResponseSplittingDetection.v1.0.json";
import numericOverUnderFlowSchema from "../../schemas/NumericOverUnderflowDetection.v1.0.json";
import openRedirectSchema from "../../schemas/OpenRedirectDetection.v1.0.json";
import privacyBannerSchema from "../../schemas/PrivacyBannerDetection.v1.0.json";
import xssFormSchema from "../../schemas/XssFormDetection.v1.0.json";
import xssPathSchema from "../../schemas/XssPathDetection.v1.0.json";
import xssQueryParamSchema from "../../schemas/XssQueryParameterDetection.v1.0.json";

const iconButtonStyles: Partial<IButtonStyles> = {
  root: { marginBottom: -3 },
};
const iconStackTokens: IStackTokens = {
  childrenGap: 30,
  maxWidth: 500,
};
const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

const Form = withTheme(FluentUITheme);
interface IDetectionDetailFormProps {
  onDetectionChange: (detectionDetail: ScanDefinitionDetectionModel) => void;
  onDeleteDetectionClick: () => void;
  detail: ScanDefinitionDetectionModel;
}
export type ConfigProp = { key: string; value: string };
export const DetectionDetailForm: React.FunctionComponent<
  IDetectionDetailFormProps
> = (props: IDetectionDetailFormProps) => {
  const detectionOptions: IDropdownOption[] = [];
  const { data: supportedDetections } = useGetDetectionsQuery();
  const [supportedConfigurationSchema, setSupportedConfigurationSchema] =
    React.useState({});
  const [latestConfigurationVersion, setLatestConfigurationVersion] =
    React.useState("");

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  let currentDetectionModel = supportedDetections?.values?.find(
    (d) => d.id === props.detail.id
  );

  React.useEffect(() => {
    (async () => {
      const latestConfigurationSchema =
        currentDetectionModel?.configurationSchemas?.sort(
          (configuration) =>
            new Date().getTime() -
            new Date(
              configuration.whenCreated ?? new Date().getTime()
            ).getTime()
        )[0];
      if (
        !latestConfigurationSchema ||
        !latestConfigurationSchema.configurationSchemaUrl
      ) {
        return;
      }

      if (currentDetectionModel) {
        switch (currentDetectionModel.id) {
          case "none": {
            setSupportedConfigurationSchema({});
            break;
          }
          case "consentmodaldetection": {
            setSupportedConfigurationSchema(consentModalSchema);
            break;
          }
          case "cookiechangedetection": {
            setSupportedConfigurationSchema(cookieChangeSchema);
            break;
          }
          case "cookiecollectiondetection": {
            setSupportedConfigurationSchema(cookieCollectionSchema);
            break;
          }
          case "httpresponsesplittingdetection": {
            setSupportedConfigurationSchema(httpResponseSplittingSchema);
            break;
          }
          case "numericoverunderflowdetection": {
            setSupportedConfigurationSchema(numericOverUnderFlowSchema);
            break;
          }
          case "openredirectdetection": {
            setSupportedConfigurationSchema(openRedirectSchema);
            break;
          }
          case "privacybannerdetection": {
            setSupportedConfigurationSchema(privacyBannerSchema);
            break;
          }
          case "xssformdetection": {
            setSupportedConfigurationSchema(xssFormSchema);
            break;
          }
          case "xsspathdetection": {
            setSupportedConfigurationSchema(xssPathSchema);
            break;
          }
          case "xssqueryparameterdetection": {
            setSupportedConfigurationSchema(xssQueryParamSchema);
            break;
          }
          case "restlerfuzzer": {
            setSupportedConfigurationSchema(apiFuzzingSchema);
            break;
          }
          case "contentsecuritypolicydetection": {
            setSupportedConfigurationSchema(contentSecurityPolicySchema);
            break;
          }
        }
        if (latestConfigurationSchema.configurationSchemaVersion) {
          setLatestConfigurationVersion(
            latestConfigurationSchema.configurationSchemaVersion
          );
        }
      }
    })();
  }, [currentDetectionModel]);

  if (!supportedDetections?.values) {
    return <div>Loading detections...</div>;
  }
  supportedDetections.values.forEach((detection) => {
    detectionOptions.push({
      key: detection.id,
      text: detection.name,
    });
  });

  return (
    <>
      <Stack.Item align="baseline">
        <IconButton
          id="deleteDetection"
          iconProps={{ iconName: "Delete" }}
          title="Delete Detection"
          ariaLabel="Delete Detection Icon"
          onClick={props.onDeleteDetectionClick}
          styles={iconButtonStyles}
        />
      </Stack.Item>
      <Stack.Item styles={subEntityStackStyle} tokens={iconStackTokens}>
        <Dropdown
          placeholder="Select a detection"
          label="Detection Selection"
          options={detectionOptions}
          styles={dropdownStyles}
          onChange={(event, option) => {
            props.onDetectionChange({
              ...props.detail,
              // Update schema in Director to have a detection id of string type
              id: option?.key as string,
            });
          }}
          selectedKey={props.detail.id}
        />
        {currentDetectionModel &&
          currentDetectionModel?.configurationSchemas &&
          currentDetectionModel?.configurationSchemas.length > 0 && (
            <DefaultButton text="Add Configuration" onClick={openPanel} />
          )}

        <Panel
          isOpen={isOpen}
          onDismiss={dismissPanel}
          isLightDismiss
          type={currentDetectionModel && currentDetectionModel.id === "restlerfuzzer" ? PanelType.large : PanelType.medium}
        >
          <Form
            schema={supportedConfigurationSchema}
            uiSchema={{
              "ui:submitButtonOptions": {
                norender: false,
                props: {
                  className: "detectionConfigSubmit",
                  disabled: false,
                },
                submitText: "Save",
              },
              "ui:title": "",
            }}
            formData={props.detail.configuration}
            onSubmit={(e) => {
              props.onDetectionChange({
                ...props.detail,
                configuration: e.formData,
                configurationSchemaVersion: latestConfigurationVersion,
              });
              dismissPanel();
            }}
          />
        </Panel>
      </Stack.Item>
    </>
  );
};
