import { IButtonStyles, IconButton, TextField } from "@fluentui/react";
import React from "react";

const iconProps = { iconName: "Delete" };
const iconButtonStyles: Partial<IButtonStyles> = {
  root: { marginBottom: -3 },
};

interface IEntityStringListProps {
  onEntityChange: (entities: string[]) => void;
  entities: string[];
  index: number;
  title: string;
  getErrorMessage?: (value: string) => string;
}

export const EntityStringList: React.FunctionComponent<
  IEntityStringListProps
> = (props: IEntityStringListProps) => {
  return (
    <>
      <IconButton
        id={`${props.title}_deleteButton`}
        iconProps={iconProps}
        title="Delete"
        ariaLabel="Delete Icon"
        onClick={() => {
          const deletingEntities = [...props.entities];
          deletingEntities.splice(props.index, 1);
          props.onEntityChange(deletingEntities);
        }}
        styles={iconButtonStyles}
      />
      <TextField
        required
        title={props.title}
        onChange={(event) => {
          const updatingEntities = [...props.entities];
          updatingEntities[props.index] = event.currentTarget.value;
          props.onEntityChange(updatingEntities);
        }}
        onGetErrorMessage={props.getErrorMessage}
        value={props.entities[props.index]}
      />
    </>
  );
};
