import { DefaultButton, Label, Spinner } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { msalBaseConfig } from "../../Configurations/config";
import {
  useGetScanDefinitionsQuery,
  usePostScanRequestMutation,
} from "../../store/apiEnhancer";
import { Entity } from "../layout/Entity";
import "../layout/Entity.scss";

export const RecentScanDefinitions = () => {
  const [scanRequestPost] = usePostScanRequestMutation();
  const { data } = useGetScanDefinitionsQuery({ limit: 10 });
  let navigate = useNavigate();
  if (!data || !data.values) {
    return (
      <div>
        <Spinner label="Loading Scan Definitions..." />
      </div>
    );
  }
  if (data.values.length == 0) {
    return <div>No Scan Definitions.</div>;
  }
  return (
    <ul>
      {data.values.map((result, index) => (
        <li key={index}>
          <Entity
            children={[
              { left: "ID:", right: result.id },
              { left: "Title:", right: result.title },
              {
                left: "When Created:",
                right: result.whenCreated ?? "N/A",
              },
            ]}
            buttonsBottom={[
              <DefaultButton
                width={70}
                href={`${msalBaseConfig.redirectUri}scanDefinitions/${result.id}`}
                tabIndex={2}
              >
                Details
              </DefaultButton>,
              <DefaultButton
                size={1}
                onClick={async () => {
                  navigate("/scanDefinitions/edit/" + result.id);
                }}
                tabIndex={2}
              >
                Edit Scan Definition
              </DefaultButton>,
              <DefaultButton
                size={1}
                onClick={async () => {
                  let createdScanRequest = await scanRequestPost({
                    scanRequestPostModel: {
                      scanDefinitionId: result.id,
                    },
                  }).unwrap();
                  navigate("/scanRequests/" + createdScanRequest.id);
                }}
                tabIndex={2}
              >
                Submit Scan Request
              </DefaultButton>,
            ]}
            entityName="scanDefinition"
          />
        </li>
      ))}
    </ul>
  );
};
