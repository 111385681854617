import {
  Dropdown,
  IButtonStyles,
  IconButton,
  IDropdownOption,
  Stack,
  TextField,
} from "@fluentui/react";
import React from "react";
import { getOwnerEmailNickNameErrorMessage } from "../../inputValidator";
import { ScanDefinitionOwnerModel } from "../../store/directorApi.generated";
import { subEntityStackStyle } from "../styling";

const options: IDropdownOption[] = [
  { key: "user", text: "User" },
  { key: "group", text: "Group" },
];
const iconProps = { iconName: "Delete" };
const iconButtonStyles: Partial<IButtonStyles> = {
  root: { marginBottom: -3 },
};

interface IScanDefinitionOwnerProps {
  onOwnerChange: (owners: ScanDefinitionOwnerModel[]) => void;
  owners: ScanDefinitionOwnerModel[];
  index: number;
}
export const ScanDefinitionOwner: React.FunctionComponent<
  IScanDefinitionOwnerProps
> = (props: IScanDefinitionOwnerProps) => {
  return (
    <>
      <Stack verticalFill verticalAlign="center" styles={subEntityStackStyle}>
        <Dropdown
          placeholder="Select Owner Type"
          label="Owner Type"
          options={options}
          styles={{
            dropdown: { width: 380 },
          }}
          onChange={(event, option) => {
            const ownersCopy = [...props.owners];
            ownersCopy[props.index] = {
              ...ownersCopy[props.index],
              objectType: option?.key.toString() ?? "",
            };
            props.onOwnerChange(ownersCopy);
          }}
          selectedKey={`${props.owners[props.index].objectType}`}
        />
        <TextField
          label="Mail Nickname"
          required
          onChange={(event) => {
            let newOwners = [...props.owners];
            newOwners[props.index] = {
              ...newOwners[props.index],
              mailNickname: event.currentTarget.value,
            };
            props.onOwnerChange(newOwners);
          }}
          value={props.owners[props.index].mailNickname}
          onGetErrorMessage={getOwnerEmailNickNameErrorMessage}
        />

        <IconButton
          id="deleteOwnerButton"
          iconProps={iconProps}
          title="Delete Owner"
          ariaLabel="Delete Owner Icon"
          onClick={() => {
            const deletedOwners = [...props.owners];
            deletedOwners.splice(props.index, 1);
            props.onOwnerChange(deletedOwners);
          }}
          styles={iconButtonStyles}
        />
      </Stack>
    </>
  );
};
