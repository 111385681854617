import { BrowserAuthError } from "@azure/msal-browser";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {
  msalInstance,
  getReporterLoginRequest,
  getDirectorLoginRequest,
  intializeAuth,
} from "../auth";
import { apiBaseConfig } from "../Configurations/config";

export const getHeader = (apiName: string) => {
  const configureHeader = async (
    headers: Headers,
    api: Pick<
      BaseQueryApi,
      "getState" | "extra" | "endpoint" | "type" | "forced"
    >
  ): Promise<Headers> => {
    await intializeAuth();
    const request =
      apiName === "director"
        ? getDirectorLoginRequest()
        : apiName === "reporter"
        ? getReporterLoginRequest()
        : { scopes: [] };
    try {
      let token = await msalInstance.acquireTokenSilent(request);
      headers.append("Authorization", `Bearer ${token.accessToken}`);
    } catch (error) {
      if (
        error instanceof BrowserAuthError &&
        error.errorCode == "no_account_error"
      ) {
        msalInstance.acquireTokenRedirect(request);
        throw error;
      }
    }
    if (apiBaseConfig.subscriptionKey) {
      headers.append(
        "Ocp-Apim-Subscription-Key",
        apiBaseConfig.subscriptionKey
      );
    }

    return headers;
  };
  return configureHeader;
};
