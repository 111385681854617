import React from "react";
import { RecentScanDefinitions } from "../components/ScanDefinition/RecentScanDefinitions";
import "./Home.scss";
import { RecentScanResults } from "../components/ScanResult/RecentScanResults";
import { useTitle } from "../HeaderUpdator";
import { Stack } from "@fluentui/react";
import newBanner from "../../src/assets/new.png";

function Home() {
  useTitle("Home - WebSec Portal");
  return (
    <div className="wrapper">
      <Stack horizontal horizontalAlign="center">
        <h1>Welcome to WebSec Portal!</h1>
        <img src={newBanner} className="newBanner" alt="New Banner" />
      </Stack>
      <div className="stackContainer">
        <div className="stackItem">
          <h2>Your Scan Definitions</h2>
          <RecentScanDefinitions />
        </div>
        <div className="stackItem">
          <h2>Your Scan Results (Received in the past 72 hours)</h2>
          <RecentScanResults />
        </div>
      </div>
    </div>
  );
}

export default Home;
