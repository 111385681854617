import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  ScanDefinitionDetail,
  ScanDefinitionTypes,
} from "./components/ScanDefinition/ScanDefinitionDetail";
import { ScanDefinitionEdit } from "./components/ScanDefinition/ScanDefinitionEdit";
import { ThemeProvider } from "@fluentui/react";
import Home from "./components/Home";
import { ScanDefinitionCreation } from "./components/ScanDefinition/ScanDefinitionCreation";
import { ScanResultDetail } from "./components/ScanResult/ScanResultDetail";
import { DetectionList } from "./components/Detection/DetectionList";
import { ResultNotificationList } from "./components/ResultNotification/ResultNotificationList";
import { ScanRequestDetail } from "./components/ScanRequest/ScanRequestDetail";
import { ScanDefinitionSearch } from "./components/ScanDefinition/ScanDefinitionSearch";
import { ScanRequestSearch } from "./components/ScanRequest/ScanRequestSearch";
import { ScanResultSearch } from "./components/ScanResult/ScanResultSearch";
import { Docs } from "./components/Docs/Docs";
import Welcome from "./components/Welcome/Welcome";

const container = document.getElementById("root")!;
const root = createRoot(container);
initializeIcons();

root.render(
  <Provider store={store}>
    <ThemeProvider>
      <App>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/home" element={<Home />} />
            <Route
              path="scanDefinitions/:scanDefinitionId"
              element={<GetScanDefinitionDetail />}
            />
            <Route
              path="scanDefinitions/edit/:scanDefinitionId"
              element={<EditScanDefinition />}
            />
            <Route
              path="scanDefinitions/search"
              element={<ScanDefinitionSearch />}
            />
            <Route
              path="scanRequests/:scanRequestId"
              element={<ViewScanRequestDetail />}
            />
            <Route path="scanRequests/search" element={<ScanRequestSearch />} />
            <Route
              path="scanDefinitions/create"
              element={<ScanDefinitionCreation />}
            />
            <Route
              path="scanResults/:scanRequestId"
              element={<GetScanResult />}
            />
            <Route path="scanResults/search" element={<ScanResultSearch />} />
            <Route path="detections/" element={<DetectionList />} />
            <Route
              path="resultNotifications/"
              element={<ResultNotificationList />}
            />
            <Route path="docs" element={<Docs />} />
          </Routes>
        </BrowserRouter>
      </App>
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
);

function GetScanDefinitionDetail() {
  let params = useParams();
  let [searchParams] = useSearchParams();
  let type = searchParams.get("scanDefinitionType");
  if (!type) {
    // Set it to existing if no type is provided
    type = "existing";
  }
  if (!params.scanDefinitionId) {
    return <div>No ScanDefinition id is provided.</div>;
  }
  return (
    <ScanDefinitionDetail
      scanDefinitionId={params.scanDefinitionId}
      scanDefinitionType={type.toLowerCase() as ScanDefinitionTypes}
    ></ScanDefinitionDetail>
  );
}

function EditScanDefinition() {
  let params = useParams();
  if (!params.scanDefinitionId) {
    return <div>No ScanDefinition id for edit is provided.</div>;
  }
  return (
    <ScanDefinitionEdit scanDefinitionIdToEdit={params.scanDefinitionId} />
  );
}

function GetScanResult() {
  let params = useParams();
  if (!params.scanRequestId) {
    return <div>No Scan Request id is provided.</div>;
  }
  return (
    <ScanResultDetail scanRequestId={params.scanRequestId}></ScanResultDetail>
  );
}

function ViewScanRequestDetail() {
  let params = useParams();
  let [searchParams] = useSearchParams();
  let type = searchParams.get("scanDefinitionType");
  if (!type) {
    // Set it to existing if no type is provided
    type = "existing";
  }
  if (!params.scanRequestId) {
    return <div>No ScanRequest id is provided.</div>;
  }
  return (
    <ScanRequestDetail
      scanRequestId={params.scanRequestId}
      scanDefinitionType={type.toLowerCase() as ScanDefinitionTypes}
    ></ScanRequestDetail>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
