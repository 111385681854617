import { PrimaryButton } from "@fluentui/react";
import { ISearchBoxStyles, SearchBox } from "@fluentui/react/lib/SearchBox";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../../HeaderUpdator";
import { guidPattern } from "../../inputValidator";
import "../layout/Entity.scss";

const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: 400 } };

/* eslint-disable react/jsx-no-bind */
export const ScanRequestSearch = () => {
  const [scanRequestId, setScanRequestId] = React.useState("");
  const [isGuidValid, setIsGuidValid] = React.useState(true);
  let navigate = useNavigate();
  useTitle("Search - Scan Request");

  return (
    <>
      <div className="addIconStack searchBox">
        <div className="addIconStackItem">
          <SearchBox
            styles={searchBoxStyles}
            placeholder="Enter Scan Request Id"
            onSearch={(id) =>
              id.match(guidPattern)
                ? navigate(`/scanRequests/${id}`)
                : setIsGuidValid(false)
            }
            onChange={(e) => {
              setScanRequestId(e?.currentTarget.value ?? "");
              e?.currentTarget.value.length == 0 ||
              e?.currentTarget.value.match(guidPattern)
                ? setIsGuidValid(true)
                : setIsGuidValid(false);
            }}
          />
        </div>
        <div className="addIconStackItem">
          <PrimaryButton
            onClick={() => navigate(`/scanRequests/${scanRequestId}`)}
          >
            Search
          </PrimaryButton>
        </div>
      </div>
      {!isGuidValid && (
        <span className="error-message">Input value is not a valid Guid</span>
      )}
    </>
  );
};
