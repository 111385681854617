import { DefaultButton, Stack } from "@fluentui/react";
import newBanner from "../../../src/assets/new.png";
import "../Home.scss";

function WelcomeMessage(props: any) {
  return (
    <div className="wrapper; welcomeMessage">
      <Stack horizontal horizontalAlign="center">
        <h1>Welcome to WebSec Portal!</h1>
        <img src={newBanner} className="newBanner" alt="New Banner" />
      </Stack>
      <p>
        WebSec is a website scanner to check for compliance issues. You can
        schedule a scan of your website and receive your results via email
        and/or SARIF. Some of the vulnerabilities it checks for includes Cross
        Site Scripting, Open Redirect, and Clickjacking.
      </p>
      <p>Sign in to see your existing scans and create new scans.</p>
      <DefaultButton onClick={() => props.onClick()}>
        Documentation
      </DefaultButton>
    </div>
  );
}

export default WelcomeMessage;
