import { reporterBaseApi as api } from "./reporterApiBase";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getScanResult: build.query<GetScanResultApiResponse, GetScanResultApiArg>({
      query: (queryArg) => ({ url: `/ScanResults/${queryArg.scanRequestId}` }),
    }),
    getScanResults: build.query<
      GetScanResultsApiResponse,
      GetScanResultsApiArg
    >({
      query: (queryArg) => ({
        url: `/ScanResults`,
        params: {
          limit: queryArg.limit,
          hoursToGoBack: queryArg.hoursToGoBack,
          scanRequestIds: queryArg.scanRequestIds,
        },
      }),
    }),
    getFindingSuppression: build.query<
      GetFindingSuppressionApiResponse,
      GetFindingSuppressionApiArg
    >({
      query: (queryArg) => ({
        url: `/Suppressions/${queryArg.findingSuppressionId}`,
      }),
    }),
    deleteFindingSuppression: build.mutation<
      DeleteFindingSuppressionApiResponse,
      DeleteFindingSuppressionApiArg
    >({
      query: (queryArg) => ({
        url: `/Suppressions/${queryArg.findingSuppressionId}`,
        method: "DELETE",
      }),
    }),
    postFindingSuppression: build.mutation<
      PostFindingSuppressionApiResponse,
      PostFindingSuppressionApiArg
    >({
      query: (queryArg) => ({
        url: `/Suppressions`,
        method: "POST",
        body: queryArg.microsoftWebSecReporterResultProcessingSubSystemModelsFindingSuppression,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reporterApi };
export type GetScanResultApiResponse =
  /** status 200 Matching Scan Result found. */ MicrosoftCodeAnalysisSarifSarifLogRead;
export type GetScanResultApiArg = {
  /** Unique identifier of the Scan Request for the scan result. */
  scanRequestId: string;
};
export type GetScanResultsApiResponse =
  /** status 200 Matching Scan Results found. */ MicrosoftWebSecReporterResultProcessingSubSystemModelsCollectionResponseModel1MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResultRead;
export type GetScanResultsApiArg = {
  /** Max number of ScanResult to retrieve. */
  limit?: number;
  /** Hours to trace back for filtering to query scan results. */
  hoursToGoBack?: number;
  /** Collection of Scan Request Ids to be searched for. */
  scanRequestIds?: string[];
};
export type GetFindingSuppressionApiResponse =
  /** status 200 Matched suppressed entity found. */ MicrosoftWebSecReporterResultProcessingSubSystemModelsFindingSuppression;
export type GetFindingSuppressionApiArg = {
  /** Unique identifier of the suppressed entity to search. */
  findingSuppressionId: string;
};
export type DeleteFindingSuppressionApiResponse =
  /** status 200 Suppressed entity was updated successfully. */ MicrosoftWebSecReporterResultProcessingSubSystemModelsFindingSuppression;
export type DeleteFindingSuppressionApiArg = {
  /** Unique identifier of the suppressed entity to be deleted. */
  findingSuppressionId: string;
};
export type PostFindingSuppressionApiResponse =
  /** status 201 Suppressed entity created successfully. */ MicrosoftWebSecReporterResultProcessingSubSystemModelsFindingSuppression;
export type PostFindingSuppressionApiArg = {
  /** A suppressed entity to be inserted. */
  microsoftWebSecReporterResultProcessingSubSystemModelsFindingSuppression: MicrosoftWebSecReporterResultProcessingSubSystemModelsFindingSuppression;
};
export type MicrosoftCodeAnalysisSarifSarifNodeKind =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31
  | 32
  | 33
  | 34
  | 35
  | 36
  | 37
  | 38
  | 39
  | 40
  | 41
  | 42
  | 43
  | 44
  | 45
  | 46
  | 47
  | 48
  | 49
  | 50
  | 51
  | 52
  | 53;
export type MicrosoftCodeAnalysisSarifSarifVersion = 0 | 1 | 2;
export type MicrosoftCodeAnalysisSarifMultiformatMessageString = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  text?: string;
  markdown?: string;
};
export type MicrosoftCodeAnalysisSarifMultiformatMessageStringRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  text?: string;
  markdown?: string;
};
export type MicrosoftCodeAnalysisSarifFailureLevel = 0 | 1 | 2 | 3;
export type MicrosoftCodeAnalysisSarifSerializedPropertyInfo = {
  serializedValue?: string;
  isString?: boolean;
};
export type MicrosoftCodeAnalysisSarifReportingConfiguration = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  enabled?: boolean;
  level?: MicrosoftCodeAnalysisSarifFailureLevel;
  rank?: number;
  parameters?: {
    [key: string]: MicrosoftCodeAnalysisSarifSerializedPropertyInfo;
  };
};
export type MicrosoftCodeAnalysisSarifReportingConfigurationRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  enabled?: boolean;
  level?: MicrosoftCodeAnalysisSarifFailureLevel;
  rank?: number;
  parameters?: {
    [key: string]: MicrosoftCodeAnalysisSarifSerializedPropertyInfo;
  };
};
export type MicrosoftCodeAnalysisSarifToolComponentReference = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  name?: string;
  index?: number;
  guid?: string;
};
export type MicrosoftCodeAnalysisSarifToolComponentReferenceRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  name?: string;
  index?: number;
  guid?: string;
};
export type MicrosoftCodeAnalysisSarifReportingDescriptorReference = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  id?: string;
  index?: number;
  guid?: string;
  toolComponent?: MicrosoftCodeAnalysisSarifToolComponentReference;
};
export type MicrosoftCodeAnalysisSarifReportingDescriptorReferenceRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  id?: string;
  index?: number;
  guid?: string;
  toolComponent?: MicrosoftCodeAnalysisSarifToolComponentReferenceRead;
};
export type MicrosoftCodeAnalysisSarifMessage = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  text?: string;
  markdown?: string;
  id?: string;
  arguments?: string[];
};
export type MicrosoftCodeAnalysisSarifMessageRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  text?: string;
  markdown?: string;
  id?: string;
  arguments?: string[];
};
export type MicrosoftCodeAnalysisSarifReportingDescriptorRelationship = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  target?: MicrosoftCodeAnalysisSarifReportingDescriptorReference;
  kinds?: string[];
  description?: MicrosoftCodeAnalysisSarifMessage;
};
export type MicrosoftCodeAnalysisSarifReportingDescriptorRelationshipRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  target?: MicrosoftCodeAnalysisSarifReportingDescriptorReferenceRead;
  kinds?: string[];
  description?: MicrosoftCodeAnalysisSarifMessageRead;
};
export type MicrosoftCodeAnalysisSarifReportingDescriptor = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  id?: string;
  deprecatedIds?: string[];
  guid?: string;
  deprecatedGuids?: string[];
  name?: string;
  deprecatedNames?: string[];
  shortDescription?: MicrosoftCodeAnalysisSarifMultiformatMessageString;
  fullDescription?: MicrosoftCodeAnalysisSarifMultiformatMessageString;
  messageStrings?: {
    [key: string]: MicrosoftCodeAnalysisSarifMultiformatMessageString;
  };
  defaultConfiguration?: MicrosoftCodeAnalysisSarifReportingConfiguration;
  helpUri?: string;
  help?: MicrosoftCodeAnalysisSarifMultiformatMessageString;
  relationships?: MicrosoftCodeAnalysisSarifReportingDescriptorRelationship[];
};
export type MicrosoftCodeAnalysisSarifReportingDescriptorRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  id?: string;
  deprecatedIds?: string[];
  guid?: string;
  deprecatedGuids?: string[];
  name?: string;
  deprecatedNames?: string[];
  shortDescription?: MicrosoftCodeAnalysisSarifMultiformatMessageStringRead;
  fullDescription?: MicrosoftCodeAnalysisSarifMultiformatMessageStringRead;
  messageStrings?: {
    [key: string]: MicrosoftCodeAnalysisSarifMultiformatMessageStringRead;
  };
  defaultConfiguration?: MicrosoftCodeAnalysisSarifReportingConfigurationRead;
  helpUri?: string;
  help?: MicrosoftCodeAnalysisSarifMultiformatMessageStringRead;
  relationships?: MicrosoftCodeAnalysisSarifReportingDescriptorRelationshipRead[];
  moniker?: string;
};
export type MicrosoftCodeAnalysisSarifArtifactLocation = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  uri?: string;
  uriBaseId?: string;
  index?: number;
  description?: MicrosoftCodeAnalysisSarifMessage;
};
export type MicrosoftCodeAnalysisSarifArtifactLocationRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  uri?: string;
  uriBaseId?: string;
  index?: number;
  description?: MicrosoftCodeAnalysisSarifMessageRead;
};
export type MicrosoftCodeAnalysisSarifToolComponentContents = 0 | 1 | 2;
export type MicrosoftCodeAnalysisSarifTranslationMetadata = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  name?: string;
  fullName?: string;
  shortDescription?: MicrosoftCodeAnalysisSarifMultiformatMessageString;
  fullDescription?: MicrosoftCodeAnalysisSarifMultiformatMessageString;
  downloadUri?: string;
  informationUri?: string;
};
export type MicrosoftCodeAnalysisSarifTranslationMetadataRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  name?: string;
  fullName?: string;
  shortDescription?: MicrosoftCodeAnalysisSarifMultiformatMessageStringRead;
  fullDescription?: MicrosoftCodeAnalysisSarifMultiformatMessageStringRead;
  downloadUri?: string;
  informationUri?: string;
};
export type MicrosoftCodeAnalysisSarifToolComponent = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  guid?: string;
  name?: string;
  organization?: string;
  product?: string;
  productSuite?: string;
  shortDescription?: MicrosoftCodeAnalysisSarifMultiformatMessageString;
  fullDescription?: MicrosoftCodeAnalysisSarifMultiformatMessageString;
  fullName?: string;
  version?: string;
  semanticVersion?: string;
  dottedQuadFileVersion?: string;
  releaseDateUtc?: string;
  downloadUri?: string;
  informationUri?: string;
  globalMessageStrings?: {
    [key: string]: MicrosoftCodeAnalysisSarifMultiformatMessageString;
  };
  notifications?: MicrosoftCodeAnalysisSarifReportingDescriptor[];
  rules?: MicrosoftCodeAnalysisSarifReportingDescriptor[];
  taxa?: MicrosoftCodeAnalysisSarifReportingDescriptor[];
  locations?: MicrosoftCodeAnalysisSarifArtifactLocation[];
  language?: string;
  contents?: MicrosoftCodeAnalysisSarifToolComponentContents;
  isComprehensive?: boolean;
  localizedDataSemanticVersion?: string;
  minimumRequiredLocalizedDataSemanticVersion?: string;
  associatedComponent?: MicrosoftCodeAnalysisSarifToolComponentReference;
  translationMetadata?: MicrosoftCodeAnalysisSarifTranslationMetadata;
  supportedTaxonomies?: MicrosoftCodeAnalysisSarifToolComponentReference[];
};
export type MicrosoftCodeAnalysisSarifToolComponentRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  guid?: string;
  name?: string;
  organization?: string;
  product?: string;
  productSuite?: string;
  shortDescription?: MicrosoftCodeAnalysisSarifMultiformatMessageStringRead;
  fullDescription?: MicrosoftCodeAnalysisSarifMultiformatMessageStringRead;
  fullName?: string;
  version?: string;
  semanticVersion?: string;
  dottedQuadFileVersion?: string;
  releaseDateUtc?: string;
  downloadUri?: string;
  informationUri?: string;
  globalMessageStrings?: {
    [key: string]: MicrosoftCodeAnalysisSarifMultiformatMessageStringRead;
  };
  notifications?: MicrosoftCodeAnalysisSarifReportingDescriptorRead[];
  rules?: MicrosoftCodeAnalysisSarifReportingDescriptorRead[];
  taxa?: MicrosoftCodeAnalysisSarifReportingDescriptorRead[];
  locations?: MicrosoftCodeAnalysisSarifArtifactLocationRead[];
  language?: string;
  contents?: MicrosoftCodeAnalysisSarifToolComponentContents;
  isComprehensive?: boolean;
  localizedDataSemanticVersion?: string;
  minimumRequiredLocalizedDataSemanticVersion?: string;
  associatedComponent?: MicrosoftCodeAnalysisSarifToolComponentReferenceRead;
  translationMetadata?: MicrosoftCodeAnalysisSarifTranslationMetadataRead;
  supportedTaxonomies?: MicrosoftCodeAnalysisSarifToolComponentReferenceRead[];
};
export type MicrosoftCodeAnalysisSarifTool = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  driver?: MicrosoftCodeAnalysisSarifToolComponent;
  extensions?: MicrosoftCodeAnalysisSarifToolComponent[];
};
export type MicrosoftCodeAnalysisSarifToolRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  driver?: MicrosoftCodeAnalysisSarifToolComponentRead;
  extensions?: MicrosoftCodeAnalysisSarifToolComponentRead[];
};
export type MicrosoftCodeAnalysisSarifConfigurationOverride = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  configuration?: MicrosoftCodeAnalysisSarifReportingConfiguration;
  descriptor?: MicrosoftCodeAnalysisSarifReportingDescriptorReference;
};
export type MicrosoftCodeAnalysisSarifConfigurationOverrideRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  configuration?: MicrosoftCodeAnalysisSarifReportingConfigurationRead;
  descriptor?: MicrosoftCodeAnalysisSarifReportingDescriptorReferenceRead;
};
export type SystemNumericsBigInteger = {};
export type SystemNumericsBigIntegerRead = {
  isPowerOfTwo?: boolean;
  isZero?: boolean;
  isOne?: boolean;
  isEven?: boolean;
  sign?: number;
};
export type MicrosoftCodeAnalysisSarifAddress = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  absoluteAddress?: number;
  relativeAddress?: number;
  length?: number;
  kind?: string;
  name?: string;
  fullyQualifiedName?: string;
  offsetFromParent?: number;
  index?: number;
  parentIndex?: number;
};
export type MicrosoftCodeAnalysisSarifAddressRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  absoluteAddress?: number;
  relativeAddress?: number;
  length?: number;
  kind?: string;
  name?: string;
  fullyQualifiedName?: string;
  offsetFromParent?: number;
  index?: number;
  parentIndex?: number;
};
export type MicrosoftCodeAnalysisSarifArtifactContent = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  text?: string;
  binary?: string;
  rendered?: MicrosoftCodeAnalysisSarifMultiformatMessageString;
};
export type MicrosoftCodeAnalysisSarifArtifactContentRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  text?: string;
  binary?: string;
  rendered?: MicrosoftCodeAnalysisSarifMultiformatMessageStringRead;
};
export type MicrosoftCodeAnalysisSarifRegion = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  startLine?: number;
  startColumn?: number;
  endLine?: number;
  endColumn?: number;
  charOffset?: number;
  charLength?: number;
  byteOffset?: number;
  byteLength?: number;
  snippet?: MicrosoftCodeAnalysisSarifArtifactContent;
  message?: MicrosoftCodeAnalysisSarifMessage;
  sourceLanguage?: string;
};
export type MicrosoftCodeAnalysisSarifRegionRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  startLine?: number;
  startColumn?: number;
  endLine?: number;
  endColumn?: number;
  charOffset?: number;
  charLength?: number;
  byteOffset?: number;
  byteLength?: number;
  snippet?: MicrosoftCodeAnalysisSarifArtifactContentRead;
  message?: MicrosoftCodeAnalysisSarifMessageRead;
  sourceLanguage?: string;
  isBinaryRegion?: boolean;
  isLineColumnBasedTextRegion?: boolean;
  isOffsetBasedTextRegion?: boolean;
};
export type MicrosoftCodeAnalysisSarifPhysicalLocation = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  address?: MicrosoftCodeAnalysisSarifAddress;
  artifactLocation?: MicrosoftCodeAnalysisSarifArtifactLocation;
  region?: MicrosoftCodeAnalysisSarifRegion;
  contextRegion?: MicrosoftCodeAnalysisSarifRegion;
};
export type MicrosoftCodeAnalysisSarifPhysicalLocationRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  address?: MicrosoftCodeAnalysisSarifAddressRead;
  artifactLocation?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
  region?: MicrosoftCodeAnalysisSarifRegionRead;
  contextRegion?: MicrosoftCodeAnalysisSarifRegionRead;
};
export type MicrosoftCodeAnalysisSarifLogicalLocation = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  name?: string;
  index?: number;
  fullyQualifiedName?: string;
  decoratedName?: string;
  parentIndex?: number;
  kind?: string;
};
export type MicrosoftCodeAnalysisSarifLogicalLocationRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  name?: string;
  index?: number;
  fullyQualifiedName?: string;
  decoratedName?: string;
  parentIndex?: number;
  kind?: string;
};
export type MicrosoftCodeAnalysisSarifLocationRelationship = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  target?: number;
  kinds?: string[];
  description?: MicrosoftCodeAnalysisSarifMessage;
};
export type MicrosoftCodeAnalysisSarifLocationRelationshipRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  target?: number;
  kinds?: string[];
  description?: MicrosoftCodeAnalysisSarifMessageRead;
};
export type MicrosoftCodeAnalysisSarifLocation = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  id?: SystemNumericsBigInteger;
  physicalLocation?: MicrosoftCodeAnalysisSarifPhysicalLocation;
  logicalLocations?: MicrosoftCodeAnalysisSarifLogicalLocation[];
  message?: MicrosoftCodeAnalysisSarifMessage;
  annotations?: MicrosoftCodeAnalysisSarifRegion[];
  relationships?: MicrosoftCodeAnalysisSarifLocationRelationship[];
  logicalLocation?: MicrosoftCodeAnalysisSarifLogicalLocation;
};
export type MicrosoftCodeAnalysisSarifLocationRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  id?: SystemNumericsBigIntegerRead;
  physicalLocation?: MicrosoftCodeAnalysisSarifPhysicalLocationRead;
  logicalLocations?: MicrosoftCodeAnalysisSarifLogicalLocationRead[];
  message?: MicrosoftCodeAnalysisSarifMessageRead;
  annotations?: MicrosoftCodeAnalysisSarifRegionRead[];
  relationships?: MicrosoftCodeAnalysisSarifLocationRelationshipRead[];
  logicalLocation?: MicrosoftCodeAnalysisSarifLogicalLocationRead;
};
export type MicrosoftCodeAnalysisSarifStackFrame = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  location?: MicrosoftCodeAnalysisSarifLocation;
  module?: string;
  threadId?: number;
  parameters?: string[];
};
export type MicrosoftCodeAnalysisSarifStackFrameRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  location?: MicrosoftCodeAnalysisSarifLocationRead;
  module?: string;
  threadId?: number;
  parameters?: string[];
};
export type MicrosoftCodeAnalysisSarifStack = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  message?: MicrosoftCodeAnalysisSarifMessage;
  frames?: MicrosoftCodeAnalysisSarifStackFrame[];
};
export type MicrosoftCodeAnalysisSarifStackRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  message?: MicrosoftCodeAnalysisSarifMessageRead;
  frames?: MicrosoftCodeAnalysisSarifStackFrameRead[];
};
export type MicrosoftCodeAnalysisSarifExceptionData = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  kind?: string;
  message?: string;
  stack?: MicrosoftCodeAnalysisSarifStack;
  innerExceptions?: MicrosoftCodeAnalysisSarifExceptionData[];
};
export type MicrosoftCodeAnalysisSarifExceptionDataRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  kind?: string;
  message?: string;
  stack?: MicrosoftCodeAnalysisSarifStackRead;
  innerExceptions?: MicrosoftCodeAnalysisSarifExceptionDataRead[];
};
export type MicrosoftCodeAnalysisSarifNotification = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  locations?: MicrosoftCodeAnalysisSarifLocation[];
  message?: MicrosoftCodeAnalysisSarifMessage;
  level?: MicrosoftCodeAnalysisSarifFailureLevel;
  threadId?: number;
  timeUtc?: string;
  exception?: MicrosoftCodeAnalysisSarifExceptionData;
  descriptor?: MicrosoftCodeAnalysisSarifReportingDescriptorReference;
  associatedRule?: MicrosoftCodeAnalysisSarifReportingDescriptorReference;
};
export type MicrosoftCodeAnalysisSarifNotificationRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  locations?: MicrosoftCodeAnalysisSarifLocationRead[];
  message?: MicrosoftCodeAnalysisSarifMessageRead;
  level?: MicrosoftCodeAnalysisSarifFailureLevel;
  threadId?: number;
  timeUtc?: string;
  exception?: MicrosoftCodeAnalysisSarifExceptionDataRead;
  descriptor?: MicrosoftCodeAnalysisSarifReportingDescriptorReferenceRead;
  associatedRule?: MicrosoftCodeAnalysisSarifReportingDescriptorReferenceRead;
};
export type MicrosoftCodeAnalysisSarifInvocation = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  commandLine?: string;
  arguments?: string[];
  responseFiles?: MicrosoftCodeAnalysisSarifArtifactLocation[];
  startTimeUtc?: string;
  endTimeUtc?: string;
  exitCode?: number;
  ruleConfigurationOverrides?: MicrosoftCodeAnalysisSarifConfigurationOverride[];
  notificationConfigurationOverrides?: MicrosoftCodeAnalysisSarifConfigurationOverride[];
  toolExecutionNotifications?: MicrosoftCodeAnalysisSarifNotification[];
  toolConfigurationNotifications?: MicrosoftCodeAnalysisSarifNotification[];
  exitCodeDescription?: string;
  exitSignalName?: string;
  exitSignalNumber?: number;
  processStartFailureMessage?: string;
  executionSuccessful?: boolean;
  machine?: string;
  account?: string;
  processId?: number;
  executableLocation?: MicrosoftCodeAnalysisSarifArtifactLocation;
  workingDirectory?: MicrosoftCodeAnalysisSarifArtifactLocation;
  environmentVariables?: {
    [key: string]: string;
  };
  stdin?: MicrosoftCodeAnalysisSarifArtifactLocation;
  stdout?: MicrosoftCodeAnalysisSarifArtifactLocation;
  stderr?: MicrosoftCodeAnalysisSarifArtifactLocation;
  stdoutStderr?: MicrosoftCodeAnalysisSarifArtifactLocation;
};
export type MicrosoftCodeAnalysisSarifInvocationRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  commandLine?: string;
  arguments?: string[];
  responseFiles?: MicrosoftCodeAnalysisSarifArtifactLocationRead[];
  startTimeUtc?: string;
  endTimeUtc?: string;
  exitCode?: number;
  ruleConfigurationOverrides?: MicrosoftCodeAnalysisSarifConfigurationOverrideRead[];
  notificationConfigurationOverrides?: MicrosoftCodeAnalysisSarifConfigurationOverrideRead[];
  toolExecutionNotifications?: MicrosoftCodeAnalysisSarifNotificationRead[];
  toolConfigurationNotifications?: MicrosoftCodeAnalysisSarifNotificationRead[];
  exitCodeDescription?: string;
  exitSignalName?: string;
  exitSignalNumber?: number;
  processStartFailureMessage?: string;
  executionSuccessful?: boolean;
  machine?: string;
  account?: string;
  processId?: number;
  executableLocation?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
  workingDirectory?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
  environmentVariables?: {
    [key: string]: string;
  };
  stdin?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
  stdout?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
  stderr?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
  stdoutStderr?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
};
export type MicrosoftCodeAnalysisSarifConversion = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  tool?: MicrosoftCodeAnalysisSarifTool;
  invocation?: MicrosoftCodeAnalysisSarifInvocation;
  analysisToolLogFiles?: MicrosoftCodeAnalysisSarifArtifactLocation[];
};
export type MicrosoftCodeAnalysisSarifConversionRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  tool?: MicrosoftCodeAnalysisSarifToolRead;
  invocation?: MicrosoftCodeAnalysisSarifInvocationRead;
  analysisToolLogFiles?: MicrosoftCodeAnalysisSarifArtifactLocationRead[];
};
export type MicrosoftCodeAnalysisSarifVersionControlDetails = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  repositoryUri?: string;
  revisionId?: string;
  branch?: string;
  revisionTag?: string;
  asOfTimeUtc?: string;
  mappedTo?: MicrosoftCodeAnalysisSarifArtifactLocation;
};
export type MicrosoftCodeAnalysisSarifVersionControlDetailsRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  repositoryUri?: string;
  revisionId?: string;
  branch?: string;
  revisionTag?: string;
  asOfTimeUtc?: string;
  mappedTo?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
};
export type MicrosoftCodeAnalysisSarifArtifactRoles =
  | 0
  | 1
  | 2
  | 4
  | 8
  | 16
  | 32
  | 64
  | 128
  | 256
  | 512
  | 1024
  | 2048
  | 4096
  | 8192
  | 16384
  | 32768
  | 65536
  | 131072
  | 262144
  | 524288
  | 1048576
  | 2097152
  | 4194304;
export type MicrosoftCodeAnalysisSarifArtifact = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  description?: MicrosoftCodeAnalysisSarifMessage;
  location?: MicrosoftCodeAnalysisSarifArtifactLocation;
  parentIndex?: number;
  offset?: number;
  length?: number;
  roles?: MicrosoftCodeAnalysisSarifArtifactRoles;
  mimeType?: string;
  contents?: MicrosoftCodeAnalysisSarifArtifactContent;
  encoding?: string;
  sourceLanguage?: string;
  hashes?: {
    [key: string]: string;
  };
  lastModifiedTimeUtc?: string;
};
export type MicrosoftCodeAnalysisSarifArtifactRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  description?: MicrosoftCodeAnalysisSarifMessageRead;
  location?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
  parentIndex?: number;
  offset?: number;
  length?: number;
  roles?: MicrosoftCodeAnalysisSarifArtifactRoles;
  mimeType?: string;
  contents?: MicrosoftCodeAnalysisSarifArtifactContentRead;
  encoding?: string;
  sourceLanguage?: string;
  hashes?: {
    [key: string]: string;
  };
  lastModifiedTimeUtc?: string;
};
export type MicrosoftCodeAnalysisSarifNode = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  id?: string;
  label?: MicrosoftCodeAnalysisSarifMessage;
  location?: MicrosoftCodeAnalysisSarifLocation;
  children?: MicrosoftCodeAnalysisSarifNode[];
};
export type MicrosoftCodeAnalysisSarifNodeRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  id?: string;
  label?: MicrosoftCodeAnalysisSarifMessageRead;
  location?: MicrosoftCodeAnalysisSarifLocationRead;
  children?: MicrosoftCodeAnalysisSarifNodeRead[];
};
export type MicrosoftCodeAnalysisSarifEdge = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  id?: string;
  label?: MicrosoftCodeAnalysisSarifMessage;
  sourceNodeId?: string;
  targetNodeId?: string;
};
export type MicrosoftCodeAnalysisSarifEdgeRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  id?: string;
  label?: MicrosoftCodeAnalysisSarifMessageRead;
  sourceNodeId?: string;
  targetNodeId?: string;
};
export type MicrosoftCodeAnalysisSarifGraph = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  description?: MicrosoftCodeAnalysisSarifMessage;
  nodes?: MicrosoftCodeAnalysisSarifNode[];
  edges?: MicrosoftCodeAnalysisSarifEdge[];
};
export type MicrosoftCodeAnalysisSarifGraphRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  description?: MicrosoftCodeAnalysisSarifMessageRead;
  nodes?: MicrosoftCodeAnalysisSarifNodeRead[];
  edges?: MicrosoftCodeAnalysisSarifEdgeRead[];
};
export type MicrosoftCodeAnalysisSarifResultKind = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type MicrosoftCodeAnalysisSarifThreadFlowLocationImportance = 0 | 1 | 2;
export type MicrosoftCodeAnalysisSarifWebRequest = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  index?: number;
  protocol?: string;
  version?: string;
  target?: string;
  method?: string;
  headers?: {
    [key: string]: string;
  };
  parameters?: {
    [key: string]: string;
  };
  body?: MicrosoftCodeAnalysisSarifArtifactContent;
};
export type MicrosoftCodeAnalysisSarifWebRequestRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  index?: number;
  protocol?: string;
  version?: string;
  target?: string;
  method?: string;
  headers?: {
    [key: string]: string;
  };
  parameters?: {
    [key: string]: string;
  };
  body?: MicrosoftCodeAnalysisSarifArtifactContentRead;
};
export type MicrosoftCodeAnalysisSarifWebResponse = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  index?: number;
  protocol?: string;
  version?: string;
  statusCode?: number;
  reasonPhrase?: string;
  headers?: {
    [key: string]: string;
  };
  body?: MicrosoftCodeAnalysisSarifArtifactContent;
  noResponseReceived?: boolean;
};
export type MicrosoftCodeAnalysisSarifWebResponseRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  index?: number;
  protocol?: string;
  version?: string;
  statusCode?: number;
  reasonPhrase?: string;
  headers?: {
    [key: string]: string;
  };
  body?: MicrosoftCodeAnalysisSarifArtifactContentRead;
  noResponseReceived?: boolean;
};
export type MicrosoftCodeAnalysisSarifThreadFlowLocation = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  index?: number;
  location?: MicrosoftCodeAnalysisSarifLocation;
  stack?: MicrosoftCodeAnalysisSarifStack;
  kinds?: string[];
  taxa?: MicrosoftCodeAnalysisSarifReportingDescriptorReference[];
  module?: string;
  state?: {
    [key: string]: MicrosoftCodeAnalysisSarifMultiformatMessageString;
  };
  nestingLevel?: number;
  executionOrder?: number;
  executionTimeUtc?: string;
  importance?: MicrosoftCodeAnalysisSarifThreadFlowLocationImportance;
  webRequest?: MicrosoftCodeAnalysisSarifWebRequest;
  webResponse?: MicrosoftCodeAnalysisSarifWebResponse;
};
export type MicrosoftCodeAnalysisSarifThreadFlowLocationRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  index?: number;
  location?: MicrosoftCodeAnalysisSarifLocationRead;
  stack?: MicrosoftCodeAnalysisSarifStackRead;
  kinds?: string[];
  taxa?: MicrosoftCodeAnalysisSarifReportingDescriptorReferenceRead[];
  module?: string;
  state?: {
    [key: string]: MicrosoftCodeAnalysisSarifMultiformatMessageStringRead;
  };
  nestingLevel?: number;
  executionOrder?: number;
  executionTimeUtc?: string;
  importance?: MicrosoftCodeAnalysisSarifThreadFlowLocationImportance;
  webRequest?: MicrosoftCodeAnalysisSarifWebRequestRead;
  webResponse?: MicrosoftCodeAnalysisSarifWebResponseRead;
};
export type MicrosoftCodeAnalysisSarifThreadFlow = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  id?: string;
  message?: MicrosoftCodeAnalysisSarifMessage;
  initialState?: any;
  immutableState?: any;
  locations?: MicrosoftCodeAnalysisSarifThreadFlowLocation[];
};
export type MicrosoftCodeAnalysisSarifThreadFlowRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  id?: string;
  message?: MicrosoftCodeAnalysisSarifMessageRead;
  initialState?: any;
  immutableState?: any;
  locations?: MicrosoftCodeAnalysisSarifThreadFlowLocationRead[];
};
export type MicrosoftCodeAnalysisSarifCodeFlow = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  message?: MicrosoftCodeAnalysisSarifMessage;
  threadFlows?: MicrosoftCodeAnalysisSarifThreadFlow[];
};
export type MicrosoftCodeAnalysisSarifCodeFlowRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  message?: MicrosoftCodeAnalysisSarifMessageRead;
  threadFlows?: MicrosoftCodeAnalysisSarifThreadFlowRead[];
};
export type MicrosoftCodeAnalysisSarifEdgeTraversal = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  edgeId?: string;
  message?: MicrosoftCodeAnalysisSarifMessage;
  finalState?: {
    [key: string]: MicrosoftCodeAnalysisSarifMultiformatMessageString;
  };
  stepOverEdgeCount?: number;
};
export type MicrosoftCodeAnalysisSarifEdgeTraversalRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  edgeId?: string;
  message?: MicrosoftCodeAnalysisSarifMessageRead;
  finalState?: {
    [key: string]: MicrosoftCodeAnalysisSarifMultiformatMessageStringRead;
  };
  stepOverEdgeCount?: number;
};
export type MicrosoftCodeAnalysisSarifGraphTraversal = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  runGraphIndex?: number;
  resultGraphIndex?: number;
  description?: MicrosoftCodeAnalysisSarifMessage;
  initialState?: {
    [key: string]: MicrosoftCodeAnalysisSarifMultiformatMessageString;
  };
  immutableState?: any;
  edgeTraversals?: MicrosoftCodeAnalysisSarifEdgeTraversal[];
};
export type MicrosoftCodeAnalysisSarifGraphTraversalRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  runGraphIndex?: number;
  resultGraphIndex?: number;
  description?: MicrosoftCodeAnalysisSarifMessageRead;
  initialState?: {
    [key: string]: MicrosoftCodeAnalysisSarifMultiformatMessageStringRead;
  };
  immutableState?: any;
  edgeTraversals?: MicrosoftCodeAnalysisSarifEdgeTraversalRead[];
};
export type MicrosoftCodeAnalysisSarifSuppressionKind = 0 | 1 | 2;
export type MicrosoftCodeAnalysisSarifSuppressionStatus = 0 | 1 | 2 | 3;
export type MicrosoftCodeAnalysisSarifSuppression = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  guid?: string;
  kind?: MicrosoftCodeAnalysisSarifSuppressionKind;
  status?: MicrosoftCodeAnalysisSarifSuppressionStatus;
  justification?: string;
  location?: MicrosoftCodeAnalysisSarifLocation;
};
export type MicrosoftCodeAnalysisSarifSuppressionRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  guid?: string;
  kind?: MicrosoftCodeAnalysisSarifSuppressionKind;
  status?: MicrosoftCodeAnalysisSarifSuppressionStatus;
  justification?: string;
  location?: MicrosoftCodeAnalysisSarifLocationRead;
};
export type MicrosoftCodeAnalysisSarifBaselineState = 0 | 1 | 2 | 3 | 4;
export type MicrosoftCodeAnalysisSarifRectangle = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
  message?: MicrosoftCodeAnalysisSarifMessage;
};
export type MicrosoftCodeAnalysisSarifRectangleRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
  message?: MicrosoftCodeAnalysisSarifMessageRead;
};
export type MicrosoftCodeAnalysisSarifAttachment = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  description?: MicrosoftCodeAnalysisSarifMessage;
  artifactLocation?: MicrosoftCodeAnalysisSarifArtifactLocation;
  regions?: MicrosoftCodeAnalysisSarifRegion[];
  rectangles?: MicrosoftCodeAnalysisSarifRectangle[];
};
export type MicrosoftCodeAnalysisSarifAttachmentRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  description?: MicrosoftCodeAnalysisSarifMessageRead;
  artifactLocation?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
  regions?: MicrosoftCodeAnalysisSarifRegionRead[];
  rectangles?: MicrosoftCodeAnalysisSarifRectangleRead[];
};
export type MicrosoftCodeAnalysisSarifResultProvenance = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  firstDetectionTimeUtc?: string;
  lastDetectionTimeUtc?: string;
  firstDetectionRunGuid?: string;
  lastDetectionRunGuid?: string;
  invocationIndex?: number;
  conversionSources?: MicrosoftCodeAnalysisSarifPhysicalLocation[];
};
export type MicrosoftCodeAnalysisSarifResultProvenanceRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  firstDetectionTimeUtc?: string;
  lastDetectionTimeUtc?: string;
  firstDetectionRunGuid?: string;
  lastDetectionRunGuid?: string;
  invocationIndex?: number;
  conversionSources?: MicrosoftCodeAnalysisSarifPhysicalLocationRead[];
};
export type MicrosoftCodeAnalysisSarifReplacement = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  deletedRegion?: MicrosoftCodeAnalysisSarifRegion;
  insertedContent?: MicrosoftCodeAnalysisSarifArtifactContent;
};
export type MicrosoftCodeAnalysisSarifReplacementRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  deletedRegion?: MicrosoftCodeAnalysisSarifRegionRead;
  insertedContent?: MicrosoftCodeAnalysisSarifArtifactContentRead;
};
export type MicrosoftCodeAnalysisSarifArtifactChange = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  artifactLocation?: MicrosoftCodeAnalysisSarifArtifactLocation;
  replacements?: MicrosoftCodeAnalysisSarifReplacement[];
};
export type MicrosoftCodeAnalysisSarifArtifactChangeRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  artifactLocation?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
  replacements?: MicrosoftCodeAnalysisSarifReplacementRead[];
};
export type MicrosoftCodeAnalysisSarifFix = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  description?: MicrosoftCodeAnalysisSarifMessage;
  artifactChanges?: MicrosoftCodeAnalysisSarifArtifactChange[];
};
export type MicrosoftCodeAnalysisSarifFixRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  description?: MicrosoftCodeAnalysisSarifMessageRead;
  artifactChanges?: MicrosoftCodeAnalysisSarifArtifactChangeRead[];
};
export type MicrosoftCodeAnalysisSarifResult = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  ruleId?: string;
  ruleIndex?: number;
  rule?: MicrosoftCodeAnalysisSarifReportingDescriptorReference;
  kind?: MicrosoftCodeAnalysisSarifResultKind;
  level?: MicrosoftCodeAnalysisSarifFailureLevel;
  message?: MicrosoftCodeAnalysisSarifMessage;
  analysisTarget?: MicrosoftCodeAnalysisSarifArtifactLocation;
  locations?: MicrosoftCodeAnalysisSarifLocation[];
  guid?: string;
  correlationGuid?: string;
  occurrenceCount?: number;
  partialFingerprints?: {
    [key: string]: string;
  };
  fingerprints?: {
    [key: string]: string;
  };
  stacks?: MicrosoftCodeAnalysisSarifStack[];
  codeFlows?: MicrosoftCodeAnalysisSarifCodeFlow[];
  graphs?: MicrosoftCodeAnalysisSarifGraph[];
  graphTraversals?: MicrosoftCodeAnalysisSarifGraphTraversal[];
  relatedLocations?: MicrosoftCodeAnalysisSarifLocation[];
  suppressions?: MicrosoftCodeAnalysisSarifSuppression[];
  baselineState?: MicrosoftCodeAnalysisSarifBaselineState;
  rank?: number;
  attachments?: MicrosoftCodeAnalysisSarifAttachment[];
  hostedViewerUri?: string;
  workItemUris?: string[];
  provenance?: MicrosoftCodeAnalysisSarifResultProvenance;
  fixes?: MicrosoftCodeAnalysisSarifFix[];
  taxa?: MicrosoftCodeAnalysisSarifReportingDescriptorReference[];
  webRequest?: MicrosoftCodeAnalysisSarifWebRequest;
  webResponse?: MicrosoftCodeAnalysisSarifWebResponse;
  run?: MicrosoftCodeAnalysisSarifRun;
};
export type MicrosoftCodeAnalysisSarifResultRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  ruleId?: string;
  ruleIndex?: number;
  rule?: MicrosoftCodeAnalysisSarifReportingDescriptorReferenceRead;
  kind?: MicrosoftCodeAnalysisSarifResultKind;
  level?: MicrosoftCodeAnalysisSarifFailureLevel;
  message?: MicrosoftCodeAnalysisSarifMessageRead;
  analysisTarget?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
  locations?: MicrosoftCodeAnalysisSarifLocationRead[];
  guid?: string;
  correlationGuid?: string;
  occurrenceCount?: number;
  partialFingerprints?: {
    [key: string]: string;
  };
  fingerprints?: {
    [key: string]: string;
  };
  stacks?: MicrosoftCodeAnalysisSarifStackRead[];
  codeFlows?: MicrosoftCodeAnalysisSarifCodeFlowRead[];
  graphs?: MicrosoftCodeAnalysisSarifGraphRead[];
  graphTraversals?: MicrosoftCodeAnalysisSarifGraphTraversalRead[];
  relatedLocations?: MicrosoftCodeAnalysisSarifLocationRead[];
  suppressions?: MicrosoftCodeAnalysisSarifSuppressionRead[];
  baselineState?: MicrosoftCodeAnalysisSarifBaselineState;
  rank?: number;
  attachments?: MicrosoftCodeAnalysisSarifAttachmentRead[];
  hostedViewerUri?: string;
  workItemUris?: string[];
  provenance?: MicrosoftCodeAnalysisSarifResultProvenanceRead;
  fixes?: MicrosoftCodeAnalysisSarifFixRead[];
  taxa?: MicrosoftCodeAnalysisSarifReportingDescriptorReferenceRead[];
  webRequest?: MicrosoftCodeAnalysisSarifWebRequestRead;
  webResponse?: MicrosoftCodeAnalysisSarifWebResponseRead;
  run?: MicrosoftCodeAnalysisSarifRun;
};
export type MicrosoftCodeAnalysisSarifRunAutomationDetails = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  description?: MicrosoftCodeAnalysisSarifMessage;
  id?: string;
  guid?: string;
  correlationGuid?: string;
};
export type MicrosoftCodeAnalysisSarifRunAutomationDetailsRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  description?: MicrosoftCodeAnalysisSarifMessageRead;
  id?: string;
  guid?: string;
  correlationGuid?: string;
};
export type MicrosoftCodeAnalysisSarifColumnKind = 0 | 1 | 2;
export type MicrosoftCodeAnalysisSarifExternalPropertyFileReference = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  location?: MicrosoftCodeAnalysisSarifArtifactLocation;
  guid?: string;
  itemCount?: number;
};
export type MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  location?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
  guid?: string;
  itemCount?: number;
};
export type MicrosoftCodeAnalysisSarifExternalPropertyFileReferences = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  conversion?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference;
  graphs?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference[];
  externalizedProperties?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference;
  artifacts?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference[];
  invocations?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference[];
  logicalLocations?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference[];
  threadFlowLocations?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference[];
  results?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference[];
  taxonomies?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference[];
  addresses?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference[];
  driver?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference;
  extensions?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference[];
  policies?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference[];
  translations?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference[];
  webRequests?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference[];
  webResponses?: MicrosoftCodeAnalysisSarifExternalPropertyFileReference[];
};
export type MicrosoftCodeAnalysisSarifExternalPropertyFileReferencesRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  conversion?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead;
  graphs?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead[];
  externalizedProperties?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead;
  artifacts?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead[];
  invocations?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead[];
  logicalLocations?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead[];
  threadFlowLocations?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead[];
  results?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead[];
  taxonomies?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead[];
  addresses?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead[];
  driver?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead;
  extensions?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead[];
  policies?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead[];
  translations?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead[];
  webRequests?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead[];
  webResponses?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferenceRead[];
};
export type MicrosoftCodeAnalysisSarifSpecialLocations = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  displayBase?: MicrosoftCodeAnalysisSarifArtifactLocation;
};
export type MicrosoftCodeAnalysisSarifSpecialLocationsRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  displayBase?: MicrosoftCodeAnalysisSarifArtifactLocationRead;
};
export type MicrosoftCodeAnalysisSarifRun = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  tool?: MicrosoftCodeAnalysisSarifTool;
  invocations?: MicrosoftCodeAnalysisSarifInvocation[];
  conversion?: MicrosoftCodeAnalysisSarifConversion;
  language?: string;
  versionControlProvenance?: MicrosoftCodeAnalysisSarifVersionControlDetails[];
  originalUriBaseIds?: {
    [key: string]: MicrosoftCodeAnalysisSarifArtifactLocation;
  };
  artifacts?: MicrosoftCodeAnalysisSarifArtifact[];
  logicalLocations?: MicrosoftCodeAnalysisSarifLogicalLocation[];
  graphs?: MicrosoftCodeAnalysisSarifGraph[];
  results?: MicrosoftCodeAnalysisSarifResult[];
  automationDetails?: MicrosoftCodeAnalysisSarifRunAutomationDetails;
  runAggregates?: MicrosoftCodeAnalysisSarifRunAutomationDetails[];
  baselineGuid?: string;
  redactionTokens?: string[];
  defaultEncoding?: string;
  defaultSourceLanguage?: string;
  newlineSequences?: string[];
  columnKind?: MicrosoftCodeAnalysisSarifColumnKind;
  externalPropertyFileReferences?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferences;
  threadFlowLocations?: MicrosoftCodeAnalysisSarifThreadFlowLocation[];
  taxonomies?: MicrosoftCodeAnalysisSarifToolComponent[];
  addresses?: MicrosoftCodeAnalysisSarifAddress[];
  translations?: MicrosoftCodeAnalysisSarifToolComponent[];
  policies?: MicrosoftCodeAnalysisSarifToolComponent[];
  webRequests?: MicrosoftCodeAnalysisSarifWebRequest[];
  webResponses?: MicrosoftCodeAnalysisSarifWebResponse[];
  specialLocations?: MicrosoftCodeAnalysisSarifSpecialLocations;
};
export type MicrosoftCodeAnalysisSarifRunRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  tool?: MicrosoftCodeAnalysisSarifToolRead;
  invocations?: MicrosoftCodeAnalysisSarifInvocationRead[];
  conversion?: MicrosoftCodeAnalysisSarifConversionRead;
  language?: string;
  versionControlProvenance?: MicrosoftCodeAnalysisSarifVersionControlDetailsRead[];
  originalUriBaseIds?: {
    [key: string]: MicrosoftCodeAnalysisSarifArtifactLocationRead;
  };
  artifacts?: MicrosoftCodeAnalysisSarifArtifactRead[];
  logicalLocations?: MicrosoftCodeAnalysisSarifLogicalLocationRead[];
  graphs?: MicrosoftCodeAnalysisSarifGraphRead[];
  results?: MicrosoftCodeAnalysisSarifResultRead[];
  automationDetails?: MicrosoftCodeAnalysisSarifRunAutomationDetailsRead;
  runAggregates?: MicrosoftCodeAnalysisSarifRunAutomationDetailsRead[];
  baselineGuid?: string;
  redactionTokens?: string[];
  defaultEncoding?: string;
  defaultSourceLanguage?: string;
  newlineSequences?: string[];
  columnKind?: MicrosoftCodeAnalysisSarifColumnKind;
  externalPropertyFileReferences?: MicrosoftCodeAnalysisSarifExternalPropertyFileReferencesRead;
  threadFlowLocations?: MicrosoftCodeAnalysisSarifThreadFlowLocationRead[];
  taxonomies?: MicrosoftCodeAnalysisSarifToolComponentRead[];
  addresses?: MicrosoftCodeAnalysisSarifAddressRead[];
  translations?: MicrosoftCodeAnalysisSarifToolComponentRead[];
  policies?: MicrosoftCodeAnalysisSarifToolComponentRead[];
  webRequests?: MicrosoftCodeAnalysisSarifWebRequestRead[];
  webResponses?: MicrosoftCodeAnalysisSarifWebResponseRead[];
  specialLocations?: MicrosoftCodeAnalysisSarifSpecialLocationsRead;
};
export type MicrosoftCodeAnalysisSarifPropertyBag = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  tags?: string[];
};
export type MicrosoftCodeAnalysisSarifExternalProperties = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  schema?: string;
  version?: MicrosoftCodeAnalysisSarifSarifVersion;
  guid?: string;
  runGuid?: string;
  conversion?: MicrosoftCodeAnalysisSarifConversion;
  graphs?: MicrosoftCodeAnalysisSarifGraph[];
  externalizedProperties?: MicrosoftCodeAnalysisSarifPropertyBag;
  artifacts?: MicrosoftCodeAnalysisSarifArtifact[];
  invocations?: MicrosoftCodeAnalysisSarifInvocation[];
  logicalLocations?: MicrosoftCodeAnalysisSarifLogicalLocation[];
  threadFlowLocations?: MicrosoftCodeAnalysisSarifThreadFlowLocation[];
  results?: MicrosoftCodeAnalysisSarifResult[];
  taxonomies?: MicrosoftCodeAnalysisSarifToolComponent[];
  driver?: MicrosoftCodeAnalysisSarifToolComponent;
  extensions?: MicrosoftCodeAnalysisSarifToolComponent[];
  policies?: MicrosoftCodeAnalysisSarifToolComponent[];
  translations?: MicrosoftCodeAnalysisSarifToolComponent[];
  addresses?: MicrosoftCodeAnalysisSarifAddress[];
  webRequests?: MicrosoftCodeAnalysisSarifWebRequest[];
  webResponses?: MicrosoftCodeAnalysisSarifWebResponse[];
};
export type MicrosoftCodeAnalysisSarifExternalPropertiesRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  schema?: string;
  version?: MicrosoftCodeAnalysisSarifSarifVersion;
  guid?: string;
  runGuid?: string;
  conversion?: MicrosoftCodeAnalysisSarifConversionRead;
  graphs?: MicrosoftCodeAnalysisSarifGraphRead[];
  externalizedProperties?: MicrosoftCodeAnalysisSarifPropertyBag;
  artifacts?: MicrosoftCodeAnalysisSarifArtifactRead[];
  invocations?: MicrosoftCodeAnalysisSarifInvocationRead[];
  logicalLocations?: MicrosoftCodeAnalysisSarifLogicalLocationRead[];
  threadFlowLocations?: MicrosoftCodeAnalysisSarifThreadFlowLocationRead[];
  results?: MicrosoftCodeAnalysisSarifResultRead[];
  taxonomies?: MicrosoftCodeAnalysisSarifToolComponentRead[];
  driver?: MicrosoftCodeAnalysisSarifToolComponentRead;
  extensions?: MicrosoftCodeAnalysisSarifToolComponentRead[];
  policies?: MicrosoftCodeAnalysisSarifToolComponentRead[];
  translations?: MicrosoftCodeAnalysisSarifToolComponentRead[];
  addresses?: MicrosoftCodeAnalysisSarifAddressRead[];
  webRequests?: MicrosoftCodeAnalysisSarifWebRequestRead[];
  webResponses?: MicrosoftCodeAnalysisSarifWebResponseRead[];
};
export type MicrosoftCodeAnalysisSarifSarifLog = {
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  schemaUri?: string;
  version?: MicrosoftCodeAnalysisSarifSarifVersion;
  runs?: MicrosoftCodeAnalysisSarifRun[];
  inlineExternalProperties?: MicrosoftCodeAnalysisSarifExternalProperties[];
};
export type MicrosoftCodeAnalysisSarifSarifLogRead = {
  propertyNames?: string[];
  tags?: string[];
  sarifNodeKind?: MicrosoftCodeAnalysisSarifSarifNodeKind;
  schemaUri?: string;
  version?: MicrosoftCodeAnalysisSarifSarifVersion;
  runs?: MicrosoftCodeAnalysisSarifRunRead[];
  inlineExternalProperties?: MicrosoftCodeAnalysisSarifExternalPropertiesRead[];
};
export type MicrosoftAspNetCoreMvcProblemDetails = {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  [key: string]: any;
};
export type MicrosoftWebSecReporterResultProcessingSubSystemModelsCallbackUrl =
  {
    /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.CallbackUrl entity. */
    id?: string;
    /** Gets or sets the minimum failure level that the callback notification should be triggered. */
    minSeverity?: number;
    /** Gets or sets a value indicating whether the callback notification should be triggered for the scans with findings or not. */
    notifyOnFindingsOnly?: boolean;
    scanResult?: MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResult;
    /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanResult entity. */
    scanResultID?: string;
    /** Gets or sets the callback url. */
    url?: string;
  };
export type MicrosoftWebSecReporterResultProcessingSubSystemModelsFindingFingerprint =
  {
    finding?: MicrosoftWebSecReporterResultProcessingSubSystemModelsFinding;
    /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.FindingFingerprint.Finding entity. */
    findingID?: string;
    /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.FindingFingerprint entity. */
    fingerprint?: string;
    /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.FindingFingerprint in byte array. */
    fingerprintBinary?: string;
    /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.FindingFingerprint entity. */
    id?: string;
    /** Gets or sets the versioning for Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.FindingFingerprint. */
    version?: string;
  };
export type MicrosoftWebSecReporterResultProcessingSubSystemModelsFindingSuppression =
  {
    /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.Finding entity. */
    findingID?: string;
    /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.FindingSuppression entity. */
    id?: string;
    /** Gets or sets the reason of why the Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.Finding needs to be suppressed. */
    justification?: string;
    /** Gets or sets datetime of when the Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.Finding was suppressed. */
    whenSuppressed?: string;
  };
export type MicrosoftWebSecReporterResultProcessingSubSystemModelsFinding = {
  /** Gets or sets the collection of the Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.FindingFingerprint entities. */
  findingFingerprints?: MicrosoftWebSecReporterResultProcessingSubSystemModelsFindingFingerprint[];
  /** Gets or sets the collection of associated Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.FindingSuppression entities. */
  findingSuppressions?: MicrosoftWebSecReporterResultProcessingSubSystemModelsFindingSuppression[];
  /** Gets or sets the description message of the Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.Finding. */
  description?: string;
  /** Gets or sets the id of the detection that found the Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.Finding. */
  detectionID?: string;
  /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.Finding entity. */
  id?: string;
  /** Gets or sets the severity level of the Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.Finding. */
  level?: string;
  /** Gets or sets the Region that vulnerability was detected in. */
  regionID?: string;
  /** Gets or sets the collection of the Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanResultFinding entities. */
  scanResultFindings?: MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResultFinding[];
  /** Gets or sets the name of the screenshot that contains Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.Finding evidence. */
  screenshotFileName?: string;
  /** Gets or sets the target url with the Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.Finding enitity. */
  url?: string;
};
export type MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResultFinding =
  {
    finding?: MicrosoftWebSecReporterResultProcessingSubSystemModelsFinding;
    /** Gets or sets the unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.Finding enitity. */
    findingID?: string;
    /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanResultFinding entity. */
    id?: string;
    scanResult?: MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResult;
    /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanResult entity. */
    scanResultID?: string;
  };
export type MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResultOwner =
  {
    /** Gets or sets unique identifier of the identity on AAD. */
    aadObjectId?: string;
    /** Gets or sets the unique identifier of this Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanResultOwner. */
    id?: string;
    /** Gets or sets friendly name of the AAD identity. */
    mailNickname?: string;
    /** Gets or sets type of the owner identity. */
    objectType?: string;
    scanResult?: MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResult;
    /** Gets or sets the unique identifier of the Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanResultOwner.ScanResult that is associated to the owner. */
    scanResultId?: string;
  };
export type MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResultRecipient =
  {
    /** Gets or sets the email address which should receive notifications. */
    emailAddress?: string;
    /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanResultRecipient entity. */
    id?: string;
    /** Gets or sets the minimum failure level that the email notification should be sent. */
    minSeverity?: number;
    /** Gets or sets a value indicating whether the email notification should be sent for the scans with findings or not. */
    notifyOnFindingsOnly?: boolean;
    scanResult?: MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResult;
    /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanResult entity. */
    scanResultID?: string;
  };
export type MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResult = {
  /** Gets or sets the application id that created the scan definition. */
  applicationID?: string;
  /** Gets or sets Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.CallbackUrl entities. */
  callbackUrls?: MicrosoftWebSecReporterResultProcessingSubSystemModelsCallbackUrl[];
  /** Gets or sets unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanResult entity. */
  id?: string;
  /** Gets or sets a value indicating whether the Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.Finding detected for the Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanRequest requires a Incident creation. */
  isIcmEnabled?: boolean;
  /** Gets or sets the unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanDefinition. */
  scanDefinitionID?: string;
  /** Gets or sets the collection of the Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanResultFinding entities. */
  scanResultFindings?: MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResultFinding[];
  /** Gets or sets a collection of owner AAD identity for the scan result. */
  scanResultOwners?: MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResultOwner[];
  /** Gets or sets the aliases of the Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanResult recipients. */
  scanResultRecipients?: MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResultRecipient[];
  /** Gets or sets the unique identifier of Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.ScanRequest. */
  scanRequestID?: string;
  /** Gets or sets datetime of when the scan instance is completed. */
  whenCompleted?: string;
  /** Gets or sets datetime of when the scan instance started execution. */
  whenStarted?: string;
};
export type MicrosoftWebSecReporterResultProcessingSubSystemModelsCollectionResponseModel1MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResult =
  {
    /** Gets or sets objects returned from API query. */
    values?: MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResult[];
  };
export type MicrosoftWebSecReporterResultProcessingSubSystemModelsCollectionResponseModel1MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResultRead =
  {
    /** Gets number of objects returned in Microsoft.WebSec.Reporter.ResultProcessingSubSystem.Models.CollectionResponseModel`1.Values. */
    count?: number;
    /** Gets or sets objects returned from API query. */
    values?: MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResult[];
  };
export const {
  useGetScanResultQuery,
  useGetScanResultsQuery,
  useGetFindingSuppressionQuery,
  useDeleteFindingSuppressionMutation,
  usePostFindingSuppressionMutation,
} = injectedRtkApi;
