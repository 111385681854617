import * as React from "react";
import { ReactNode } from "react";
import "./Entity.scss";

export type LeftRightObj =
  | {
    left?: ReactNode;
    right?: ReactNode | undefined;
  }
  | false;
export type EntityProps = {
  children: LeftRightObj[];
  buttonsBottom?: ReactNode;
  entityName?: string;
  labeledBy?: string;
  separatorLabel?: string;
};

export const Entity: React.FunctionComponent<EntityProps> = (
  props: EntityProps
) => {
  return (
    <div className={`entityContainer ${props.entityName}`}>
      {props.separatorLabel && (
        <React.Fragment>
          <p className={`entitiyGroup groupTitle`}>{props.separatorLabel}</p>
        </React.Fragment>
      )}
      <div
        key={`${props.separatorLabel}_content`}
        className={`entity ms-bgColor-themePrimary`}
      >
        {props.children.map((child, index) =>
          child ? (
            <React.Fragment key={index}>
              <div className={`left`} aria-labelledby={props.labeledBy}>
                {child.left}
              </div>
              <div className={`right`} aria-labelledby={props.labeledBy}>
                {child.right}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment key={index}></React.Fragment>
          )
        )}
      </div>
      {props.buttonsBottom}
    </div>
  );
};
