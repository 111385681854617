import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Spinner,
} from "@fluentui/react";
import { ReactNode } from "react";
import { msalBaseConfig } from "../../Configurations/config";
import { useTitle } from "../../HeaderUpdator";
import { useGetScanRequestQuery } from "../../store/apiEnhancer";

import { Entity } from "../layout/Entity";
import "../layout/Entity.scss";
import {
  getConfigReactNodes,
  ScanDefinitionTypes,
} from "../ScanDefinition/ScanDefinitionDetail";

type ScanRequestDetailsProps = {
  scanRequestId: string;
  scanDefinitionType: ScanDefinitionTypes;
};
export const ScanRequestDetail = (props: ScanRequestDetailsProps) => {
  const { data, isLoading, error } = useGetScanRequestQuery({
    scanRequestId: props.scanRequestId,
  });
  useTitle("Scan Request Details");

  if (isLoading) {
    return (
      <div>
        <Spinner label="Loading Scan Request..." />
      </div>
    );
  }
  if (error) {
    if ("status" in error) {
      if (error.status == 404) {
        return (
          <MessageBar
            className="errorMessageBanner"
            messageBarType={MessageBarType.error}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
          >
            {`404:
            Scan Request ${props.scanRequestId} Not Found.`}
          </MessageBar>
        );
      }
      if (error.status == 403) {
        return (
          <MessageBar
            className="errorMessageBanner"
            messageBarType={MessageBarType.error}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
          >
            {`403:
            You are not authorized to view Scan Request ${props.scanRequestId}`}
          </MessageBar>
        );
      }
    }
  }
  if (!data) {
    return <h2>No Scan Request data was availbale to be retrieved.</h2>;
  }
  let title =
    props.scanDefinitionType === "created"
      ? `Scan Definition was created successfully, and Scan Request was submitted.`
      : "Scan Request Details";

  let scanInstances: ReactNode[] = [];
  data.scanInstances?.forEach((instance, index) => {
    scanInstances.push(
      <Entity
        children={[
          {
            left: <p key={`scanInstanceId_${index}`}>Scan Instance Id:</p>,
            right: <p key={`scanInstanceId_value_${index}`}>{instance.id}</p>,
          },
          {
            left: <p key={`regionId_${index}`}>Region Id:</p>,
            right: (
              <p key={`regionId_value_${index}`}>{instance.regionId ?? ""}</p>
            ),
          },
          {
            left: <p key={`whenCompleted_${index}`}>When Completed:</p>,
            right: (
              <p key={`whenCompleted_value_${index}`}>
                {instance.whenCompleted}
              </p>
            ),
          },
        ]}
        labeledBy="scanInstances"
        entityName="scanInstance"
        key={index}
      />
    );
  });

  let resultNotifications: ReactNode[] = [];

  if (data.resultNotifications) {
    data.resultNotifications.forEach((notification) => {
      resultNotifications.push(
        <Entity
          key={notification.type}
          children={[
            { left: "Type:", right: notification.type },
            !!notification.configurationSchemaVersion && {
              left: "Configuration Schema Version:",
              right: notification.configurationSchemaVersion,
            },
            ...getConfigReactNodes(notification.configuration),
            {
              left: `Audiences:`,
              right: notification.audiences?.join(", "),
            },
            {
              left: "Notify On Findings Only:",
              right: (!!notification.notifyOnFindingsOnly).toString(),
            },
            {
              left: `Min Severity:`,
              right: notification.minSeverity ?? "Not Configured",
            },
          ]}
          entityName="resultNotification"
          labeledBy="resultNotifications"
        />
      );
    });
  }
  return (
    <>
      <h1 className="sub" aria-live="polite">
        {title}
      </h1>

      <Entity
        key={`scanRequest_${props.scanRequestId}`}
        children={[
          {
            left: <p id="scanRequestId">Scan Request Id:</p>,
            right: <p id="scanRequestId-value">{data.id}</p>,
          },
          {
            left: <p id="scanInstances">Scan Instances:</p>,
            right:
              scanInstances.length === 0
                ? "No Scan Instances created yet"
                : scanInstances,
          },
          {
            left: <p id="scanDefinitionId">Scan Definition Id:</p>,
            right: (
              <p id="scanDefinitionId-value">
                {data.scanDefinitionId ?? "Scan Definition ID Not Defined."}
              </p>
            ),
          },
          {
            left: <p id="skipCallback">Skip Callback:</p>,
            right: (
              <p id="skipCallback-value">
                {data.skipCallback
                  ? data.skipCallback.toString()
                  : "Not Configured"}
              </p>
            ),
          },
          {
            left: (
              <p id="overrideResultNotifications">
                Override Result Notifications:
              </p>
            ),
            right: (
              <p id="overrideResultNotifications-value">
                {data.overrideResultNotifications
                  ? data.overrideResultNotifications.toString()
                  : "Not Configured"}
              </p>
            ),
          },
          {
            left: (
              <p id="resultNotifications">
                Result Notifications (Override the setting in Scan Definition ):
              </p>
            ),
            right: <p id="resultNotifications-value">{resultNotifications}</p>,
          },
          {
            left: <p id="whenCreated">When Created:</p>,
            right: <p id="whenCreated-value">{data.whenCreated ?? "N/A"}</p>,
          },
          {
            left: <p id="whenUpdated">When Updated:</p>,
            right: <p id="whenUpdated-value">{data.whenUpdated ?? "N/A"}</p>,
          },
        ]}
        entityName="scanRequestDetails"
        buttonsBottom={[
          <DefaultButton
            key="viewScanDefinitionButton"
            width={70}
            href={`${msalBaseConfig.redirectUri}scanDefinitions/${data.scanDefinitionId}`}
          >
            View Scan Definition
          </DefaultButton>,
        ]}
      />
    </>
  );
};
