import { baseConfig } from "./Configurations/config";

export const guidPattern =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export const getGuidInputErrorMessage = (value: string): string => {
  return value.length == 0 || value.match(guidPattern)
    ? ""
    : `Input is not a valid Guid.`;
};
export const getOwnerEmailNickNameErrorMessage = (value: string): string => {
  return value.endsWith("@microsoft.com")
    ? `@microsoft.com is not needed. Please enter only the alias.`
    : "";
};
export const getServiceTreeIdErrorMessage = (value: string): string => {
  if (value == baseConfig.defaultServiceTreeId) {
    return `Please update this value with your Service Tree ID (current ID is for the WebXT Organization).`;
  }
  return getGuidInputErrorMessage(value);
};
export const getUrlInputErrorMessage = (value: string): string => {
  return value.length == 0 || isValidUrl(value)
    ? ""
    : `Input value is not a valid Uri.`;
};

export const getMaxScanRunTimeInputErrorMessage = (value: string): string => {
  return value.length == 0 || isMaxScanRunTimeInRange(value)
    ? ""
    : `Input value is not between 1 -120.`;
};

function isValidUrl(input: string) {
  let url;

  try {
    url = new URL(input);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

function isMaxScanRunTimeInRange(input: string) {
  let currentNumber;
  try {
    currentNumber = parseInt(input);
  } catch (_) {
    return false;
  }

  return currentNumber > 0 && currentNumber <= 120;
}
