import {
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import { ReactNode } from "react";
import { reporterApiConfig } from "../../Configurations/config";
import { useTitle } from "../../HeaderUpdator";
import { useGetScanDefinitionQuery } from "../../store/apiEnhancer";
import {
  MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResult,
  useGetScanResultsQuery,
} from "../../store/reporterApi.generated";
import { Entity } from "../layout/Entity";

type ScanResultDetailProp = {
  scanRequestId: string;
};
export const ScanResultDetail = (props: ScanResultDetailProp) => {
  const {
    data: scanResultData,
    isLoading,
    error,
  } = useGetScanResultsQuery({
    scanRequestIds: [props.scanRequestId],
  });

  if (isLoading) {
    return (
      <div>
        <Spinner label="Loading Scan Result..." />
      </div>
    );
  }
  if (error) {
    if ("status" in error) {
      if (error.status == 404) {
        return (
          <MessageBar
            className="errorMessageBanner"
            messageBarType={MessageBarType.error}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
          >
            {`404:
            Scan Result for the scan request Id: ${props.scanRequestId} Not Found.`}
          </MessageBar>
        );
      }
    }
  }

  if (scanResultData?.values && scanResultData?.values.length > 0) {
    return (
      <ScanDefinitionDetailInternal scanResult={scanResultData?.values[0]} />
    );
  } else {
    return (
      <MessageBar
        className="errorMessageBanner"
        messageBarType={MessageBarType.error}
        isMultiline={false}
        dismissButtonAriaLabel="Close"
      >
        {`Scan Result for the scan request Id: ${props.scanRequestId} not found, or you don't have permission.`}
      </MessageBar>
    );
  }
};

type ScanDefinitionInternalProps = {
  scanResult: MicrosoftWebSecReporterResultProcessingSubSystemModelsScanResult;
};
const ScanDefinitionDetailInternal = (props: ScanDefinitionInternalProps) => {
  const { data, isLoading, error } = useGetScanDefinitionQuery({
    scanDefinitionId: props.scanResult.scanDefinitionID!,
  });
  const downloadLink = `${reporterApiConfig.sarifLogDownloadLink}${props.scanResult.scanRequestID}`;
  let detections: ReactNode[] = [];
  let findings: ReactNode[] = [];
  useTitle("Scan Result Details");

  if (isLoading) {
    return (
      <div>
        <Spinner label="Loading Scan Result..." />
      </div>
    );
  }
  if (error) {
    if ("status" in error) {
      if (error.status == 404) {
        return (
          <MessageBar
            className="errorMessageBanner"
            messageBarType={MessageBarType.error}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
          >
            {`404:
            Matched Scan Definition for Scan Result ${props.scanResult.id} Not Found.`}
          </MessageBar>
        );
      }
      if (error.status == 403) {
        return (
          <MessageBar
            className="errorMessageBanner"
            messageBarType={MessageBarType.error}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
          >
            {`403:
            You are not authorized to view Scan Result ${props.scanResult.id}.`}
          </MessageBar>
        );
      }
    }
  }
  if (data) {
    data.detections?.forEach((detection) => {
      detections.push(
        <Entity
          children={[
            { left: "Detection Id:", right: detection.id },
            detection.configurationSchemaVersion && {
              left: "Config Version:",
              right: detection.configurationSchemaVersion,
            },
            detection.configuration && {
              left: "Configuration:",
              right: detection.configuration,
            },
          ]}
          entityName="detection"
        />
      );
    });

    props.scanResult.scanResultFindings?.forEach((finding, index) => {
      findings.push(
        <Entity
          key={index}
          children={[
            { left: "Finding Id:", right: finding.findingID },
            !!finding.finding?.level && {
              left: "Level:",
              right: finding.finding?.level,
            },
            !!finding.finding?.detectionID && {
              left: "Detection Id:",
              right: finding.finding?.detectionID,
            },
            !!finding.finding?.findingFingerprints?.[0] && {
              left: "FindingFingerprint:",
              right: finding.finding.findingFingerprints[0].fingerprint,
            },
            !!finding.finding?.description && {
              left: "Description:",
              right: finding.finding?.description,
            },
            !!finding.finding?.url && {
              left: "Url:",
              right: (
                <a href={finding.finding.url} title={finding.finding.url}>
                  {finding.finding.url}
                </a>
              ),
            },
            !!finding.finding?.regionID && {
              left: "Region Id:",
              right: finding.finding?.regionID,
            },
          ]}
          entityName="finding"
          labeledBy="findings"
        />
      );
    });
  }
  return (
    <>
      <h1 className="sub">Scan Result Details</h1>

      <Entity
        key={`scanResult_${props.scanResult.id}`}
        children={[
          {
            left: "",
            right: (
              <PrimaryButton
                className="downloadSarifLogButton"
                href={downloadLink}
              >
                Download SarifLog Scan Result
              </PrimaryButton>
            ),
          },
          {
            left: <div id="id">Id:</div>,
            right: <div id="id-value">{props.scanResult.id}</div>,
          },
          {
            left: <div id="scanDefinitionId">Scan Definition Id:</div>,
            right: (
              <div id="scanDefinitionId-value">
                {props.scanResult.scanDefinitionID ??
                  "Scan Definition Id Not Defined."}
              </div>
            ),
          },
          {
            left: <div id="scanRequestId">Scan Request Id:</div>,
            right: (
              <div id="scanRequestId-value">
                {props.scanResult.scanRequestID ??
                  "Scan Request ID Not Defined."}
              </div>
            ),
          },
          {
            left: <div id="applicationId">Application Id:</div>,
            right: (
              <div id="applicationId-value">
                {props.scanResult.applicationID ?? "Not Configured"}
              </div>
            ),
          },
          {
            left: <div id="whenStarted">When Started:</div>,
            right: (
              <div id="whenStarted-value">
                {props.scanResult.whenStarted ?? "Not Configured"}
              </div>
            ),
          },
          {
            left: <div id="whenCompleted">When Completed:</div>,
            right: (
              <div id="whenCompleted-value">
                {props.scanResult.whenCompleted ?? "Not Configured"}
              </div>
            ),
          },

          {
            left: <div id="findings">Findings:</div>,
            right: (
              <div id="whenCompleted-value">
                {findings.length == 0 ? "No Findings Detected" : findings}
              </div>
            ),
          },
        ]}
        entityName="scanResult"
      />
    </>
  );
};
