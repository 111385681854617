import { DefaultButton, Spinner } from "@fluentui/react";
import { msalBaseConfig } from "../../Configurations/config";
import { useGetScanResultsQuery } from "../../store/reporterApi.generated";
import { Entity } from "../layout/Entity";
import "../layout/Entity.scss";

/**
 * Renders 10 most recent scan definitions.
 * This component will be removed since it's more efficient to pass the each data in the list of 10 scan definitions to
 * ScanDefinitionDetailsSummarized component.
 *
 */
export const RecentScanResults = () => {
  const { data } = useGetScanResultsQuery({ limit: 10, hoursToGoBack: 72 });
  if (!data) {
    return (
      <div>
        <Spinner label="Loading Scan Results..." />
      </div>
    );
  }
  if (data.count == 0) {
    return <div>No Recent Scan Results.</div>;
  }
  return (
    <ul>
      {data.values && data.values.map((result, index) => (
        <li key={result.id}>
          <Entity
            key={index}
            buttonsBottom={
              <DefaultButton
                href={`${msalBaseConfig.redirectUri}scanResults/${result.scanRequestID}`}
              >
                Details
              </DefaultButton>
            }
            entityName="scanResult"
          >
            {[
              {
                left: "Scan Request Id:",
                right: result.scanRequestID ?? "Not Configured",
              },
              {
                left: "Scan Definition Id:",
                right: result.scanDefinitionID ?? "Not Configured",
              },
              {
                left: "When Started:",
                right: result.whenStarted ?? "Not Configured",
              },
              {
                left: "When Completed:",
                right: result.whenCompleted ?? "Not Configured",
              },
            ]}
          </Entity>
        </li>
      ))}
    </ul>
  );
};
