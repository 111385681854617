import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

export const Docs: React.FunctionComponent = () => {
  const [val, setVal] = useState("");

  const getText = async () => {
    fetch("docs.md")
      .then((f) => f.text())
      .then((t) => {
        setVal(t);
      });
  };

  useEffect(() => {
    document.title = "Document - WebSec";
    getText();
  }, []);

  // waiting for links to detections and regions
  // waiting for search scan res functionality

  return <ReactMarkdown>{val}</ReactMarkdown>;
};
