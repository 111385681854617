import { IDocumentCardStyles, IStackStyles } from "@fluentui/react";

export const subEntityStackStyle: IStackStyles = {
  root: {
    background: "#F5F5F5",
    padding: 10,
    border: "1px solid #F5F5F5",
  },
};

export const topEntityStackStyle: IStackStyles = {
  root: {
    background: "#dcdcdc",
    padding: 10,
    border: "2px solid #dcdcdc",
  },
};

export const entityStackStyle: IStackStyles = {
  root: {
    padding: 10,
    border: "2px solid #dcdcdc",
  },
};

export const outerEntityStackStyle: IStackStyles = {
  root: {
    background: "theme.semanticColors.bodyStandoutBackground",
    padding: 10,
    marginBottom: 10,
    marginTop:10,
    borderBottom: "3px solid #dcdcdc",
  },
};
