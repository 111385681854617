import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import * as React from "react";
import Logo from "../../assets/MicrosoftLogo.png";
import { msalInstance } from "../../auth";
import { msalBaseConfig, reporterApiConfig } from "../../Configurations/config";
import "./OperationCommandBar.scss";

type OperationBarProps = {
  isSignedIn: boolean;
};

export const OperationCommandBar: React.FunctionComponent<OperationBarProps> = (
  props: OperationBarProps
) => {
  const loginItems: ICommandBarItemProps[] = [];
  let accounts = msalInstance.getAllAccounts();

  return (
    <>
      <div className="topLogo">
        <img src={Logo} alt="" width="120" height="25" />
        &nbsp;
      </div>
      <div className="navBar">
        <CommandBar
          items={_items}
          farItemsGroupAriaLabel="Login"
          farItems={[
            accounts && accounts[0]
              ? {
                  key: "signout",
                  text: "Sign out",
                  iconOnly: false,
                  iconProps: { iconName: "Signout" },
                  onClick: () => {
                    let accounts = msalInstance.getAllAccounts();
                    const logoutRequest = {
                      account: accounts[0],
                      postLogoutRedirectUri: msalBaseConfig.redirectUri,
                    };
                    msalInstance.logoutRedirect(logoutRequest);
                  },
                }
              : {
                  key: "signIn",
                  text: "Sign in",
                  iconOnly: false,
                  iconProps: { iconName: "Signin" },
                  onClick: () => {
                    // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
                    const loginRequest = {
                      scopes: [
                        `api://${reporterApiConfig.applicationId}/access_as_user`,
                      ],
                      account: accounts[0],
                    };

                    msalInstance.loginRedirect(loginRequest);
                  },
                },
          ]}
        />
      </div>
    </>
  );
};

const _items: ICommandBarItemProps[] = [
  {
    key: "home",
    text: "Home",
    href: `${msalBaseConfig.redirectUri}home`,
  },
  {
    key: "scanDefinition",
    text: "Scan Definition",
    cacheKey: "myCacheKey1", // changing this key will invalidate this item's cache
    subMenuProps: {
      items: [
        {
          key: "create",
          text: "Create",
          href: `${msalBaseConfig.redirectUri}scanDefinitions/create`,
        },
        {
          key: "search",
          text: "Search",
          href: `${msalBaseConfig.redirectUri}scanDefinitions/search`,
        },
      ],
    },
  },
  {
    key: "scanRequest",
    text: "Scan Request",
    subMenuProps: {
      items: [
        {
          key: "search",
          text: "Search",
          href: `${msalBaseConfig.redirectUri}scanRequests/search`,
        },
      ],
    },
  },
  {
    key: "scanResult",
    text: "Scan Result",
    subMenuProps: {
      items: [
        {
          key: "Search",
          text: "Search",
          href: `${msalBaseConfig.redirectUri}scanResults/search`,
        },
      ],
    },
  },
  {
    key: "docs",
    text: "Docs",
    href: `${msalBaseConfig.redirectUri}docs`,
  },
];
