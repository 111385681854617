import { Spinner } from "@fluentui/react";
import { useTitle } from "../../HeaderUpdator";
import { useGetScanDefinitionQuery } from "../../store/directorApi.generated";
import { ScanDefinitionCreation } from "./ScanDefinitionCreation";

type ScanDefinitionEditProps = {
  scanDefinitionIdToEdit: string;
};
export const ScanDefinitionEdit: React.FunctionComponent<
  ScanDefinitionEditProps
> = (props: ScanDefinitionEditProps) => {
  const { data } = useGetScanDefinitionQuery({
    scanDefinitionId: props.scanDefinitionIdToEdit,
  });

  useTitle("Scan Definition Edit");

  if (data) {
    return <ScanDefinitionCreation scanDefinitionToEdit={data} />;
  }
  return <Spinner label="Loading..." />;
};
