import { createTheme, DefaultButton, ITheme, Spinner } from "@fluentui/react";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import "../../../src/components/layout/Entity.scss";
import { msalBaseConfig } from "../../Configurations/config";
import { useTitle } from "../../HeaderUpdator";

import {
  useGetScanDefinitionQuery,
  usePostScanRequestMutation,
} from "../../store/apiEnhancer";
import { Entity, LeftRightObj } from "../layout/Entity";
import "../layout/Entity.scss";
import { minSeverityOptions } from "../ResultNotification/ResultNotificationForm";

const separatorTheme: ITheme = createTheme({
  fonts: {
    medium: {
      fontFamily: "Monaco, Menlo, Consolas",
      fontSize: "1.5em",
    },
  },
});
export type ScanDefinitionTypes = "edited" | "created" | "existing";
type ScanDefinitionDetailsProps = {
  scanDefinitionId: string;
  scanDefinitionType: ScanDefinitionTypes;
};

export const ScanDefinitionDetail = (props: ScanDefinitionDetailsProps) => {
  const { data, isLoading, error } = useGetScanDefinitionQuery({
    scanDefinitionId: props.scanDefinitionId,
  });

  const [
    scanRequestPost, // This is the mutation trigger
    { isLoading: isUpdating }, // This is the destructured mutation result
  ] = usePostScanRequestMutation();
  let navigate = useNavigate();
  useTitle("Scan Definition Details");

  if (isLoading) {
    return (
      <div>
        <Spinner label="Loading Scan Definition..." />
      </div>
    );
  }

  if (error) {
    if ("status" in error) {
      if (error.status == 404) {
        return (
          <>
            <h2>404:</h2>
            <div>{`Scan Definition ${props.scanDefinitionId} Not Found.`}</div>
          </>
        );
      }
      if (error.status == 403) {
        return (
          <>
            <h2>403:</h2>
            <div>
              {`You are not authorized to view Scan Definition ${props.scanDefinitionId}`}
            </div>
          </>
        );
      }
    }
  }
  if (!data) {
    return <h1>No Scan Definition data was availbale to be retrieved...</h1>;
  }
  let title =
    props.scanDefinitionType === "edited"
      ? `Scan Defifnition ${props.scanDefinitionId} Edit was successful.`
      : props.scanDefinitionType === "created"
        ? `Scan Defifnition ${props.scanDefinitionId} was created successfully.`
        : "Scan Definition Detail";
  let detections: ReactNode[] = [];

  data.detections?.forEach((detection) => {
    let configEntity: LeftRightObj[] = [];
    if (detection.configuration) {
      for (const key in detection.configuration) {
        configEntity.push({ left: key, right: JSON.stringify(detection.configuration[key]) });
      }
    }
    detections.push(
      <Entity
        key={detection.id}
        children={[
          { left: "Detection Id:", right: detection.id },
          detection.configurationSchemaVersion && {
            left: "Configuration Version:",
            right: detection.configurationSchemaVersion,
          },
          detection.configuration && {
            left: "Configuration:",
            right: (
              <Entity
                key={`${detection.id}Config`}
                children={configEntity}
                labeledBy={detection.id!}
                entityName={`${detection.id}Config`}
              ></Entity>
            ),
          },
        ]}
        entityName="detection"
        labeledBy={"detections"}
      />
    );
  });

  let owners: ReactNode[] = [];
  data.owners.forEach((owner) => {
    owners.push(
      <Entity
        key={owner.mailNickname}
        children={[
          { left: "Mail NickName:", right: owner.mailNickname },
          { left: "Aad Object Id:", right: owner.aadObjectId },
          { left: "Object Type:", right: owner.objectType },
        ]}
        entityName="owner"
        labeledBy={"owners"}
      />
    );
  });
  let resultNotifications: ReactNode[] = [];

  if (data.resultNotifications) {
    data.resultNotifications.forEach((notification) => {
      resultNotifications.push(
        <Entity
          key={notification.type}
          children={[
            { left: "Type:", right: notification.type },
            !!notification.configurationSchemaVersion && {
              left: "Configuration Schema Version:",
              right: notification.configurationSchemaVersion,
            },

            ...getConfigReactNodes(notification.configuration),
            {
              left: `Audiences:`,
              right: notification.audiences?.join(", "),
            },
            {
              left: "Notify On Findings Only:",
              right: (!!notification.notifyOnFindingsOnly).toString(),
            },
            {
              left: `Min Severity:`,
              right: getSeverityText(notification.minSeverity),
            },
          ]}
          entityName="resultNotification"
          labeledBy="resultNotifications"
        />
      );
    });
  }

  let urls: ReactNode[] = [];
  data.urls.forEach((url, index) => {
    urls.push(
      <Entity
        key={index}
        children={[
          {
            left: (
              <a key={index} href={url} title={url}>
                {url}
              </a>
            ),
            right: "",
          },
        ]}
        entityName="urls"
        labeledBy={"urls"}
      />
    );
  });

  let headers: ReactNode[] = [];

  if (data.requestOptions && data.requestOptions.headers) {
    data.requestOptions.headers.forEach((header, index) => {
      headers.push(
        <Entity
          key={index}
          children={[
            {
              left: "Name:",
              right: header.name ?? "Not Configured",
            },
            {
              left: "Secret Name:",
              right: header.secretName ?? "Not Configured",
            },
            {
              left: "Value:",
              right: header.value ?? "Not Configured",
            },
          ]}
          entityName="headers"
          labeledBy={"requestOptions"}
        />
      );
    });
  }

  return (
    <>
      <h1 className="sub" aria-live="polite">
        {title}
      </h1>
      <Entity
        separatorLabel="General Information"
        key={`scanDefinition_${props.scanDefinitionId}`}
        children={[
          { left: <p id="id">Id:</p>, right: <p id="id-value">{data.id}</p> },
          {
            left: <p id="id">Is Active:</p>,
            right: <p id="isActive-value">{data.isActive?.toString()}</p>,
          },
          {
            left: <p id="title">Title:</p>,
            right: <p id="title-value">{data.title}</p>,
          },
          {
            left: <p id="owners">Owners:</p>,
            right: owners,
          },
          {
            left: <p id="serviceTreeId">Service Tree Id:</p>,
            right: (
              <p id="serviceTreeId-value">
                {data.serviceTreeId?.toString() ?? "Not Configured"}
              </p>
            ),
          },
          {
            left: <p id="appId">Application Id:</p>,
            right: (
              <p id="appId-value">
                {data.applicationId?.toString() ?? "Not Configured"}
              </p>
            ),
          },
          {
            left: <p id="whenCreated">When Created:</p>,
            right: <p id="whenCreated-value">{data.whenCreated}</p>,
          },
          {
            left: <p id="whenExpires">When Expires:</p>,
            right: (
              <p id="whenExpires-value">
                {data.whenExpires ?? "Not Configured"}
              </p>
            ),
          },
          {
            left: <p id="urls">Urls:</p>,
            right: urls,
          },
        ]}
        entityName="generalInfo"
      />
      <Entity
        separatorLabel="Detection"
        key={`scanDefinition_detection_${props.scanDefinitionId}`}
        children={[
          { left: <p id="detections"></p>, right: detections },
          {
            left: <p id="maxScanRunTime">Max Scan Run Time (Minutes):</p>,
            right: (
              <p id="maxScanRunTime-value">
                {data.maxScanRuntimeInMinutes?.toString() ?? "Not Configured"}
              </p>
            ),
          },
        ]}
        entityName="detectionRelated"
      />
      <Entity
        separatorLabel="Scheduling"
        key={`scanDefinition_scheduling_${props.scanDefinitionId}`}
        children={[
          {
            left: <p id="nextScheduleRun">Next Schedule Run:</p>,
            right: (
              <p id="nextScheduleRun-value">
                {data.nextScheduledRun?.toString() ?? "Not Configured"}
              </p>
            ),
          },

          {
            left: (
              <p id="recurrentScheduleInterval">
                Recurrent Schedule Interval (Hours):
              </p>
            ),
            right: (
              <p id="recurrentScheduleInterval-value">
                {data.recurrenceScheduleInHours?.toString() ?? "Not Configured"}
              </p>
            ),
          },

          {
            left: <p id="regions">Regions:</p>,
            right: <p id="regions-value">{data.regions?.join(", ")}</p>,
          },
        ]}
        entityName="scheduling"
      />
      <Entity
        separatorLabel="Notification"
        key={`scanDefinition_notification_${props.scanDefinitionId}`}
        children={[
          resultNotifications.length > 0
            ? {
              left: <p id="resultNotifications">Result Notifications:</p>,
              right: resultNotifications,
            }
            : {
              left: null,
              right: <p id="resultNotifications">Not Configured</p>,
            },
        ]}
        entityName="scanDefinitionDetail"
      />
      <Entity
        separatorLabel="Request Options"
        key="requestOption"
        children={[
          {
            left: <p id="authentication">Authentication:</p>,
            right: data.requestOptions?.authentication && (
              <Entity
                key="authentication-value"
                children={[
                  {
                    left: "Authentication Method:",
                    right:
                      data.requestOptions?.authentication.kind ??
                      "Not Configured",
                  },
                  !!data.requestOptions?.authentication
                    .configurationSchemaVersion && {
                    left: "Configuration Schema Version:",
                    right:
                      data.requestOptions?.authentication
                        .configurationSchemaVersion,
                  },
                  {
                    left: "Configuration:",
                    right: (
                      <Entity
                        key="authenticationConfiguration"
                        children={getConfigReactNodes(
                          data.requestOptions?.authentication.configuration
                        )}
                      />
                    ),
                  },
                ]}
                labeledBy="requestOption"
              />
            ),
          },
          {
            left: <p id="deviceKind">Device Kind:</p>,
            right: (
              <p id="deviceKind-value">
                {data.requestOptions?.deviceKind?.toString() ??
                  "Not Configured"}
              </p>
            ),
          },

          headers.length > 0 && {
            left: <p id="headers">Headers:</p>,
            right: headers,
          },
          {
            left: <p id="userAgent">User Agent:</p>,
            right: (
              <p id="userAgent-value">
                {data.requestOptions?.userAgent?.toString() ?? "Not Configured"}
              </p>
            ),
          },
        ]}
        entityName="scheduling"
      />
      <div className="submitButton">
        <DefaultButton
          href={`${msalBaseConfig.redirectUri}scanDefinitions/edit/${data.id}`}
          key="scanDefinitionEdit"
        >
          Edit
        </DefaultButton>

        <DefaultButton
          key="scanRequestSubmit"
          size={1}
          onClick={async () => {
            let createdScanRequest = await scanRequestPost({
              scanRequestPostModel: {
                scanDefinitionId: data.id,
              },
            }).unwrap();
            navigate("/scanRequests/" + createdScanRequest.id);
          }}
        >
          Submit Scan Request
        </DefaultButton>
      </div>
    </>
  );
};

export const getConfigReactNodes = (value?: any): LeftRightObj[] => {
  let result: LeftRightObj[] = [];

  if (value) {
    for (const key in value) {
      result.push({
        left: key + ":",
        right: value[key],
      });
    }
  }
  return result;
};

export const getSeverityText = (num: number | undefined): string => {
  if (num) {
    for (const p of minSeverityOptions) {
      if (p.key == num) {
        return p.text;
      }
    }
  }
  return "Not Configured";
};
