import {
  DefaultButton,
  Dropdown,
  IButtonStyles,
  IconButton,
  IDropdownOption,
  IDropdownStyles,
  IStackTokens,
  Panel,
  Stack,
  Toggle,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { withTheme } from "@rjsf/core";
import { Theme as FluentUITheme } from "@rjsf/fluent-ui";
import React, { ReactNode } from "react";
import { useGetResultNotificationsQuery } from "../../store/apiEnhancer";
import { ScanDefinitionResultNotificationModel } from "../../store/directorApi.generated";
import "../layout/Entity.scss";
import { EntityStringList } from "../ScanDefinition/EntityStringList";
import { subEntityStackStyle } from "../styling";

export const minSeverityOptions: IDropdownOption[] = [
  { key: 0, text: "None" },
  {
    key: 1,
    text: "Informational",
  },
  { key: 2, text: "Warning (Problem being detected)" },
  { key: 3, text: "Error (Serious Problem being detected)" },
];

const iconStackTokens: IStackTokens = {
  childrenGap: 30,
  maxWidth: 500,
};

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 380 },
};
const textBoxWidth: number = 400;
const Form = withTheme(FluentUITheme);
interface IResultNotificationFormProps {
  onResultNotificationChange: (
    detail: ScanDefinitionResultNotificationModel
  ) => void;
  onDeleteResultNotificationClick: () => void;
  detail: ScanDefinitionResultNotificationModel;
}
export const ResultNotificationForm = (props: IResultNotificationFormProps) => {
  const { data } = useGetResultNotificationsQuery();
  const deleteIconProps = { iconName: "Delete" };
  const iconButtonStyles: Partial<IButtonStyles> = {
    root: { marginBottom: -3 },
  };
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [
    latestResultNotificationConfigurations,
    setLatestResultConfigurations,
  ] = React.useState({});

  let matched = data?.values?.find(
    (notification) => notification.id === props.detail.type
  );

  React.useEffect(() => {
    (async () => {
      const latestConfigurationSchema = matched?.configurationSchemas?.sort(
        (configuration) =>
          new Date().getTime() -
          new Date(configuration.whenCreated ?? new Date().getTime()).getTime()
      )[0];
      if (
        !latestConfigurationSchema ||
        !latestConfigurationSchema.configurationSchemaUrl
      ) {
        return;
      }

      const configuration = await fetch(
        latestConfigurationSchema.configurationSchemaUrl
      );

      setLatestResultConfigurations(await configuration.json());
    })();
  }, [matched]);
  const currentAudienceNodes: ReactNode[] = [];
  const resultNotificationOptions: IDropdownOption[] = [];

  if (!data || !data.values) {
    return <div>Loading result notifications...</div>;
  }
  data.values.forEach((resultNotification) => {
    resultNotificationOptions.push({
      key: resultNotification.id,
      text: resultNotification.name,
    });
  });

  if (props.detail.audiences) {
    for (let i = 0; i < props.detail.audiences.length; i++) {
      currentAudienceNodes.push(
        <EntityStringList
          key={i}
          title="Audience"
          onEntityChange={(newAudiences: string[]) => {
            props.onResultNotificationChange({
              ...props.detail,
              audiences: newAudiences,
            });
          }}
          index={i}
          entities={props.detail.audiences ?? [""]}
        />
      );
    }
  }

  return (
    <>
      <Stack.Item align="baseline">
        <IconButton
          id="deleteResultNotification"
          iconProps={deleteIconProps}
          title="Delete Result Notification"
          ariaLabel="Delete Result Notification Icon"
          onClick={props.onDeleteResultNotificationClick}
          styles={iconButtonStyles}
        />
      </Stack.Item>
      <Stack.Item styles={subEntityStackStyle} tokens={iconStackTokens}>
        <Dropdown
          placeholder="Select a result notification"
          label="Result Notification Selection"
          options={resultNotificationOptions}
          styles={dropdownStyles}
          onChange={(event, option) => {
            props.onResultNotificationChange({
              ...props.detail,
              // Update schema in Director to have a detection id of string type
              type: option?.key as string,
            });
          }}
          selectedKey={props.detail.type}
        />
      </Stack.Item>
      <Stack.Item styles={subEntityStackStyle} tokens={iconStackTokens}>
        <Dropdown
          placeholder="Select Minimum Finding Level for a notification to be triggered."
          label={"Raise notification if any finding is at least"}
          options={minSeverityOptions}
          styles={dropdownStyles}
          onChange={(event, option) => {
            props.onResultNotificationChange({
              ...props.detail,
              // Update schema in Director to have a detection id of string type
              minSeverity: option?.key as number,
            });
          }}
          selectedKey={props.detail.minSeverity}
        />
      </Stack.Item>
      <Stack.Item styles={subEntityStackStyle} tokens={iconStackTokens}>
        <fieldset className="legendContainer">
          <legend className="legendMessage">
            Select if a notification always needs to be triggered, or only when
            findings are detected.
          </legend>
          <Toggle
            label="Notify on Findings Only"
            defaultChecked
            onText="Yes"
            offText="No"
            onChange={(event, option) => {
              props.onResultNotificationChange({
                ...props.detail,
                notifyOnFindingsOnly: option,
              });
            }}
          />
        </fieldset>
        <div>Audiences</div>
        {currentAudienceNodes}
        <div className="addIconStack">
          <div className="addIconStackItemLabel">Additional Audience</div>
          <div className="addIconStackItem">
            <IconButton
              id="addAudienceButton"
              iconProps={{ iconName: "Add" }}
              title="Add Audience"
              ariaLabel="Add Audience Icon"
              onClick={() => {
                if (props.detail.audiences) {
                  let newAudiences = [...props.detail.audiences];
                  newAudiences.push("");
                  props.onResultNotificationChange({
                    ...props.detail,
                    audiences: newAudiences,
                  });
                } else {
                  props.onResultNotificationChange({
                    ...props.detail,
                    audiences: [""],
                  });
                }
              }}
              styles={iconButtonStyles}
            />
          </div>
        </div>
        {matched &&
          matched?.configurationSchemas &&
          matched?.configurationSchemas.length > 0 && (
            <DefaultButton text="Add Configuration" onClick={openPanel} />
          )}
        <Panel
          isOpen={isOpen}
          onDismiss={() => {
            props.onResultNotificationChange({
              ...props.detail,
              configuration: {},
              configurationSchemaVersion: undefined,
            });
            dismissPanel();
          }}
          isLightDismiss
          isHiddenOnDismiss={false}
        >
          <Form
            schema={latestResultNotificationConfigurations}
            uiSchema={{
              "ui:submitButtonOptions": {
                norender: false,
                props: {
                  className: "notificationConfigSubmit",
                  disabled: false,
                },
                submitText: "Save",
              },
              "ui:title": "",
            }}
            formData={props.detail.configuration}
            onSubmit={(e) => {
              props.onResultNotificationChange({
                ...props.detail,
                configuration: e.formData,
              });
              dismissPanel();
            }}
          />
        </Panel>
      </Stack.Item>
    </>
  );
};
