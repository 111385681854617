import { IButtonStyles, IconButton, Stack, TextField } from "@fluentui/react";
import { RequestHeaderModel } from "../../store/directorApi.generated";
import { subEntityStackStyle } from "../styling";

interface IScanDefinitionHeaderProps {
    onHeaderChange: (headers: RequestHeaderModel[]) => void;
    headers: RequestHeaderModel[];
    index: number;
  }
  export const ScanDefinitionHeader: React.FunctionComponent<
    IScanDefinitionHeaderProps
  > = (props: IScanDefinitionHeaderProps) => {

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: { marginBottom: -3 },
      };
      
    return (
      <>
        <Stack verticalFill verticalAlign="center" styles={subEntityStackStyle}>
          <TextField
            label="Name"
            onChange={(event) => {
              let newHeaders = [...props.headers];
              newHeaders[props.index] = {
                ...newHeaders[props.index],
                name: event.currentTarget.value,
              };
              props.onHeaderChange(newHeaders);
            }}
            value={props.headers[props.index].name}
          />
          <TextField
            label="Secret Name"
            onChange={(event) => {
              let newHeaders = [...props.headers];
              newHeaders[props.index] = {
                ...newHeaders[props.index],
                secretName: event.currentTarget.value,
              };
              props.onHeaderChange(newHeaders);
            }}
            value={props.headers[props.index].secretName}
          />
          <TextField
            label="Value"
            onChange={(event) => {
              let newHeaders = [...props.headers];
              newHeaders[props.index] = {
                ...newHeaders[props.index],
                value: event.currentTarget.value,
              };
              props.onHeaderChange(newHeaders);
            }}
            value={props.headers[props.index].value}
          />
          <IconButton
            id="deleteHeaderButton"
            iconProps={{ iconName: "Delete" }}
            title="Delete Header"
            ariaLabel="Delete Header Icon"
            onClick={() => {
              const deletedHeaders = [...props.headers];
              deletedHeaders.splice(props.index, 1);
              props.onHeaderChange(deletedHeaders);
            }}
            styles={iconButtonStyles}
          />
        </Stack>
      </>
    );
        }